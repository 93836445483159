import { useMemo } from 'react'
import { Path } from 'react-hook-form'

import { getFormComponents } from '@tk/frontend/primitives'
import { Option } from '@tk/frontend/primitives/forms/SelectField'

import { DistributionFormValues } from './DistributionForm'

interface VisibilitySelectorProps {
  infoText: string
  name: Path<DistributionFormValues>
  label: string
  trueLabel?: string
}

const Form = getFormComponents<DistributionFormValues>()

export function VisibilitySelector({
  infoText,
  label,
  name,
  trueLabel,
}: Readonly<VisibilitySelectorProps>) {
  const options: Option<boolean>[] = useMemo(() => {
    return [
      { label: trueLabel ?? 'Required', value: true },
      { label: 'Hidden', value: false },
    ]
  }, [trueLabel])

  return (
    <Form.Field
      label={label}
      name={name}
      info={<Form.FieldInfo details={infoText} />}
      input={
        <Form.SelectField
          options={options}
          rules={{
            validate: {
              required: (value) => {
                if (typeof value !== 'boolean') {
                  return 'Required'
                }

                return true
              },
            },
          }}
        />
      }
    />
  )
}
