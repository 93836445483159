import { useCallback, useEffect, useMemo } from 'react'
import { useFieldArray, useFormContext } from 'react-hook-form'

import { Add, Launch } from '@mui/icons-material'
import {
  Button,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material'
import { DateTime } from 'luxon'
import { useQueryParam } from 'use-query-params'

import { getFormComponents } from '@tk/frontend/primitives'

import { RulesetEditDto } from './types'

const Form = getFormComponents<RulesetEditDto>()

export function RulesetFormLinks() {
  const form = useFormContext<RulesetEditDto>()
  const [linkUrlToAdd, setLinkUrlToAdd] = useQueryParam<string | undefined>(
    'linkUrlToAdd'
  )

  const originalUrls = useMemo(() => {
    return (form.formState?.defaultValues?.links
      ?.filter((link) => !!link?.url)
      .map((link) => link?.url) ?? []) as string[]
  }, [form.formState?.defaultValues?.links])

  const links = useFieldArray({
    control: form.control,
    name: 'links',
  })

  const addLinkItemFromQueryParams = useCallback(() => {
    if (!linkUrlToAdd) {
      return
    }

    const linkValues = form.watch('links') ?? []

    if (!linkValues.some(({ url }) => url === linkUrlToAdd)) {
      links.append({
        url: linkUrlToAdd,
        dateAdded: new Date(),
      })
    }
    setLinkUrlToAdd(undefined)
  }, [form, linkUrlToAdd, links, setLinkUrlToAdd])

  const addLink = useCallback(() => {
    links.append({
      url: '',
      dateAdded: new Date(),
    })
  }, [links])

  useEffect(() => {
    if (linkUrlToAdd) {
      addLinkItemFromQueryParams()
    }
  }, [addLinkItemFromQueryParams, linkUrlToAdd])

  return (
    <Form.Section gridColumns={12} title="Links" id="links">
      {(links.fields.length === 0 && (
        <Typography align="center">No Links Added</Typography>
      )) || (
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>URL</TableCell>
              <TableCell />
              <TableCell>Date Added</TableCell>
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {links.fields.map(({ id, url, dateAdded }, index) => {
              const isExisting = originalUrls.includes(url)
              const dateString =
                DateTime.fromJSDate(dateAdded).toFormat('HH:mm dd LLL yyyy')

              return (
                <TableRow key={id}>
                  <TableCell>
                    <Form.Field
                      name={`links.${index}.url`}
                      disabled={isExisting}
                      input={
                        <Form.TextField
                          type="url"
                          placeholder="Enter URL"
                          rules={{
                            ...Form.rules.required,
                            validate: (value) => {
                              if (
                                links.fields.some((field, index) => {
                                  const urlValue = form.getValues(
                                    `links.${index}.url`
                                  )

                                  return field.id !== id && urlValue === value
                                })
                              ) {
                                return 'Value must be unique'
                              }

                              return true
                            },
                          }}
                        />
                      }
                    />
                  </TableCell>
                  <TableCell align="center">
                    <IconButton
                      color="primary"
                      onClick={() => window.open(url, '_blank')}
                    >
                      <Launch />
                    </IconButton>
                  </TableCell>
                  <TableCell>{isExisting && dateString}</TableCell>
                  <TableCell align="right">
                    <Button
                      color="warning"
                      disabled={isExisting}
                      onClick={() => links.remove(index)}
                    >
                      Delete
                    </Button>
                  </TableCell>
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
      )}
      <Button
        fullWidth
        startIcon={<Add />}
        onClick={addLink}
        data-testid="add-link"
      >
        Add
      </Button>
    </Form.Section>
  )
}
