import { Dialog, DialogContent, DialogTitle } from '@mui/material'
import { create, useModal } from '@parameta/nice-modal-react'

import { DistributionForm, DistributionFormValues } from './DistributionForm'

type Model = {
  initialValues?: DistributionFormValues
  mode: 'create' | 'edit'
}

export const MutationModal = create<Model>(({ mode, initialValues }) => {
  const modal = useModal()

  return (
    <Dialog open={modal.visible} onClose={modal.hide}>
      <DialogTitle>
        {mode === 'create' ? 'New' : 'Update'} Distribution
      </DialogTitle>
      <DialogContent>
        <DistributionForm
          onFinished={modal.hide}
          initialValues={initialValues}
          mode={mode}
        />
      </DialogContent>
    </Dialog>
  )
})
