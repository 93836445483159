import { getFormComponents } from '@tk/frontend/primitives'

import { MetadataFormProps, RulesetEditDto } from './types'

const Form = getFormComponents<RulesetEditDto>()

export function DefaultForm({ index }: Readonly<MetadataFormProps>) {
  return (
    <Form.Field
      name={`rulesetItems.${index}.metadata`}
      label="Metadata"
      input={
        <Form.TextField
          multiline={{ minRows: 3 }}
          rules={{
            validate: (value) => {
              if (typeof value === 'string' && value.trim() !== '') {
                try {
                  JSON.parse(value)
                  return true
                } catch {
                  return 'Invalid JSON format. Please provide a valid JSON string.'
                }
              }
              return true
            },
          }}
        />
      }
    />
  )
}
