import React, { useState } from 'react'

import {
  ClickAwayListener,
  Popover,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material'
import { DateTime } from 'luxon'

import { LinkedRecordsDto } from '@lib/pgs'
import { AppLink } from '@tk/frontend/primitives'
import {
  NameDateCell,
  PairedDateCell,
  TwoLineTextCell,
} from '@tk/frontend/primitives/datagrid/cells'

import { generateRecordEditUri } from './RecordsRouter.routes'

export type LinkedRecordsPopoverProps = {
  id: number
  name: string
  source: string
  backbone: string
  status: string
  validFromDate?: Date
  validToDate?: Date
  createdDate: Date
  createdBy: string
  linkedRecords: LinkedRecordsDto[]
  linkText: string
}

export function LinkedRecordsPopover({
  id,
  name,
  source,
  backbone,
  status,
  validFromDate,
  validToDate,
  createdDate,
  createdBy,
  linkedRecords,
  linkText,
}: LinkedRecordsPopoverProps) {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

  const handleOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleLinkNavigation = (event: React.MouseEvent, uri: string) => {
    event.preventDefault()
    handleClose()
    setTimeout(() => {
      window.location.href = uri
    }, 0)
  }

  const isOpen = Boolean(anchorEl)

  return (
    <ClickAwayListener onClickAway={handleClose}>
      <div>
        <Popover
          open={isOpen}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
        >
          <Stack direction="column" padding="1rem">
            <Table size="small" style={{ width: '25rem' }}>
              <TableHead>
                <TableRow>
                  <TableCell sx={{ padding: '0.75rem', minWidth: '170px' }}>
                    Name
                  </TableCell>
                  <TableCell sx={{ padding: '0.75rem', minWidth: '120px' }}>
                    Source
                  </TableCell>
                  <TableCell sx={{ padding: '0.75rem', minWidth: '100px' }}>
                    Status
                  </TableCell>
                  <TableCell sx={{ padding: '0.75rem', minWidth: '150px' }}>
                    Valid Dates
                  </TableCell>
                  <TableCell sx={{ padding: '0.75rem', minWidth: '120px' }}>
                    Created Date
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell sx={{ padding: '0.75rem', minWidth: '340px' }}>
                    <AppLink
                      to={generateRecordEditUri(String(id))}
                      onClick={(event) =>
                        handleLinkNavigation(
                          event,
                          generateRecordEditUri(String(id))
                        )
                      }
                    >
                      {name}
                    </AppLink>
                  </TableCell>
                  <TableCell sx={{ padding: '0.75rem', minWidth: '120px' }}>
                    <TwoLineTextCell line1={backbone} line2={source} />
                  </TableCell>
                  <TableCell sx={{ padding: '0.75rem', minWidth: '100px' }}>
                    {status}
                  </TableCell>
                  <TableCell sx={{ padding: '0.75rem', minWidth: '150px' }}>
                    <PairedDateCell
                      dateOne={
                        validFromDate
                          ? DateTime.fromJSDate(validFromDate).toISO()
                          : undefined
                      }
                      dateTwo={
                        validToDate
                          ? DateTime.fromJSDate(validToDate).toISO()
                          : undefined
                      }
                    />
                  </TableCell>
                  <TableCell sx={{ padding: '0.75rem', minWidth: '150px' }}>
                    <NameDateCell
                      name={createdBy}
                      date={DateTime.fromJSDate(createdDate).toISO()}
                    />
                  </TableCell>
                </TableRow>
                {linkedRecords.map((record) => (
                  <TableRow key={record.id}>
                    <TableCell sx={{ padding: '0.75rem', minWidth: '340px' }}>
                      <AppLink
                        to={generateRecordEditUri(String(record.id))}
                        onClick={(event) =>
                          handleLinkNavigation(
                            event,
                            generateRecordEditUri(String(record.id))
                          )
                        }
                      >
                        {record.name}
                      </AppLink>
                    </TableCell>
                    <TableCell sx={{ padding: '0.75rem', minWidth: '120px' }}>
                      <TwoLineTextCell
                        line1={record.backbone?.name}
                        line2={record.source?.name}
                      />
                    </TableCell>
                    <TableCell sx={{ padding: '0.75rem', minWidth: '100px' }}>
                      {record.status}
                    </TableCell>
                    <TableCell sx={{ padding: '0.75rem', minWidth: '150px' }}>
                      <PairedDateCell
                        dateOne={
                          record.validFromDate
                            ? DateTime.fromJSDate(record.validFromDate).toISO()
                            : undefined
                        }
                        dateTwo={
                          record.validToDate
                            ? DateTime.fromJSDate(record.validToDate).toISO()
                            : undefined
                        }
                      />
                    </TableCell>
                    <TableCell sx={{ padding: '0.75rem', minWidth: '150px' }}>
                      <NameDateCell
                        name={record.createdBy}
                        date={record.createdDate}
                      />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Stack>
        </Popover>
        <Typography
          variant="body2"
          color="primary"
          style={{ cursor: 'pointer', textDecoration: 'underline' }}
          onClick={handleOpen}
        >
          {linkText}
        </Typography>
      </div>
    </ClickAwayListener>
  )
}
