import { Box, TextField } from '@mui/material'
import { DataGridPremium, useGridApiRef } from '@mui/x-data-grid-premium'

import {
  defaultProps,
  useRowSelectionModel,
  useSearch,
} from '@tk/frontend/primitives/datagrid'
import {
  TreeDataGridStyles,
  useTreeGroupingProps,
} from '@tk/frontend/primitives/datagrid/tree'
import { PageContentLayout } from '@tk/frontend/primitives/layout'

import { MarketGroupCreateButton } from './MarketGroupCreateButton'
import { TypedRowUnion } from './types'
import { useColumns } from './useColumns'
import { useRows } from './useRows'

export function StubCrud() {
  const apiRef = useGridApiRef()
  const [search, setSearch] = useSearch(apiRef)
  const selectionModel = useRowSelectionModel()

  const columns = useColumns()

  const rows = useRows()

  const groupingProps = useTreeGroupingProps(apiRef, {
    getTreeDataPath,
    getRowId,
  })

  return (
    <PageContentLayout
      title="Market Groups & Instruments"
      controls={<MarketGroupCreateButton />}
    >
      <Box display="flex" flexDirection="column" height="100%" width="100%">
        <TextField
          value={search ?? ''}
          onChange={(e) => setSearch(e.target.value)}
          label="Filter"
          variant="filled"
          inputProps={{
            role: 'search',
          }}
          fullWidth
        />

        <TreeDataGridStyles>
          <DataGridPremium
            {...defaultProps}
            //
            density="compact"
            apiRef={apiRef}
            //
            {...groupingProps}
            //
            rows={rows}
            columns={columns}
            rowSelectionModel={selectionModel.model}
            onRowSelectionModelChange={selectionModel.setModel}
            keepNonExistentRowsSelected
          />
        </TreeDataGridStyles>
      </Box>
    </PageContentLayout>
  )
}

function getTreeDataPath(row: TypedRowUnion): string[] {
  if (row._type === 'instrument') {
    return [row.multibrandMarketGroup?.name ?? '[Unassigned]', row.name]
  } else {
    return [row.name]
  }
}

function getRowId(row: TypedRowUnion) {
  return `${row._type}/${row.id}`
}
