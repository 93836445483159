import { EntitlementRulesetDto } from '@lib/pgs'

import { DefaultForm } from './DefaultForm'
import { DistributionService, RulesetRequestDtoWithItems } from './types'

export class DefaultService implements DistributionService {
  getDefaultValues = (data: EntitlementRulesetDto) =>
    data as Partial<RulesetRequestDtoWithItems>

  validateRulesetItemMetadataValues = (data: RulesetRequestDtoWithItems) => {
    return []
  }

  MetadataForm = DefaultForm
}
