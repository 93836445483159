import { useParams } from 'react-router-dom'

import * as Icons from '@mui/icons-material'
import { Button } from '@mui/material'

import { useRouteEffect } from '@lib/web'
import { trpc, useUtils } from '@tk/frontend/api'
import {
  getFormComponents,
  usePromiseNotification,
} from '@tk/frontend/primitives'

import { RrnStructureForm } from './RrnStructureForm'
import { transformFromFormData } from './transformFromFormData'
import { RrnStructureFormData, RrnStructureRequestDto } from './types'

const Form = getFormComponents<RrnStructureFormData & { id: number }>()

export function RrnStructureEditPage() {
  const { id } = useParams<{ id: string }>()
  const { initialData, rrnStructure } = useFormInitialData(id!)
  const form = Form.useForm({
    defaultValues: initialData,
  })

  useRouteEffect(() => {
    form.reset(initialData)
  })

  const notify = usePromiseNotification()
  const utils = useUtils()
  const updateMutation = trpc.multibrand.rrnStructure.update.useMutation({
    onSuccess(data) {
      utils.multibrand.invalidate()

      form.reset(transformToFormData(data))
    },
  })

  const handleSubmit = form.handleSubmit(async (values) => {
    const requestDto = transformFromFormData(values)

    await notify(
      updateMutation.mutateAsync({ ...requestDto, id: parseInt(id ?? '-1') }),
      {
        progressMessage: `Updating Root Record Name Structure ${rrnStructure.name}`,
        successMessage: `Updated Root Record Name Structure ${values.name}`,
        failureMessage: `Failed to update Root Record Name Structure`,
      }
    )
  })

  return (
    <Form.Provider {...form}>
      <Form.FormStack onSubmit={handleSubmit} submitOnCtrlEnter>
        <Form.SectionTitleRow title="Update Root Record Name Structure">
          <Button
            startIcon={<Icons.Save />}
            type="submit"
            variant="contained"
            disabled={updateMutation.isPending || !form.formState.isDirty}
          >
            Update
          </Button>
        </Form.SectionTitleRow>

        <input {...form.register('id')} hidden />
        <RrnStructureForm />
      </Form.FormStack>
    </Form.Provider>
  )
}

function transformToFormData(dto: RrnStructureRequestDto) {
  const requestDto = dto as Partial<RrnStructureFormData>

  requestDto.marketInstruments =
    dto.marketInstruments?.map(({ id }) => ({
      id,
    })) ?? []

  if (dto.retiredAt) {
    requestDto.status = 'retired'
  } else {
    requestDto.status = 'active'
  }

  return requestDto
}

function useFormInitialData(id: string) {
  const [rrnStructure] = trpc.multibrand.rrnStructure.get.useSuspenseQuery(
    parseInt(id)
  )

  return {
    initialData: transformToFormData(rrnStructure),
    rrnStructure: rrnStructure,
  }
}
