import { z } from 'zod'

export const createJsonParser = <T extends object>(
  configurationSchema: z.AnyZodObject
) => {
  return (content: string) => {
    const parsedContent = z
      .string()
      .transform((str, ctx) => {
        try {
          return JSON.parse(str)
        } catch (error) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: 'Invalid JSON',
          })
          return z.NEVER
        }
      })
      .parse(content)

    return configurationSchema.parse(parsedContent) as T
  }
}
