import { useMemo } from 'react'

import { trpc } from '@tk/frontend/api'
import { Option } from '@tk/frontend/primitives/forms/SelectField'

export function useRrnStructureOptions() {
  const [rrnStructures] = trpc.multibrand.rrnStructure.list.useSuspenseQuery()

  const rrnStructureOptions = useMemo(() => {
    return rrnStructures.map<Option>(({ name, id, retiredAt }) => {
      const label = name

      const option: Option = {
        value: id,
        label,
      }

      if (retiredAt) {
        option.group = 'Retired'
      } else {
        option.group = 'Active'
      }

      return option
    })
  }, [rrnStructures])

  return rrnStructureOptions
}
