import { When } from '@tk/frontend/primitives'
import { PageContentLayout } from '@tk/frontend/primitives/layout'

import { RulesetCreateButton } from './RulesetCreateButton'
import { RulesetCrudTable } from './RulesetCrudTable'

export function RulesetCrud() {
  return (
    <PageContentLayout
      title="Ruleset"
      controls={
        <When can="entitlements.manage">
          <RulesetCreateButton />
        </When>
      }
    >
      <RulesetCrudTable />
    </PageContentLayout>
  )
}
