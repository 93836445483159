import { useParams } from 'react-router-dom'

import * as Icons from '@mui/icons-material'
import { Button } from '@mui/material'

import { EntitlementRulesetRequestDto } from '@lib/pgs'
import { trpc, useUtils } from '@tk/frontend/api'
import {
  getFormComponents,
  usePromiseNotification,
} from '@tk/frontend/primitives'

import { useMetadataServiceLocator } from './metadata-services'
import { RulesetForm } from './RulesetForm'

const Form = getFormComponents<EntitlementRulesetRequestDto & { id: number }>()

export function RulesetEditPage() {
  const { id } = useParams<{ id: string }>()
  const initialData = useFormInitialData(id!)
  const { getMetadataServiceById } = useMetadataServiceLocator()
  const service = getMetadataServiceById(initialData.distribution?.id)

  const form = Form.useForm({
    defaultValues: service.getDefaultValues(initialData),
  })

  const notify = usePromiseNotification()

  const updateMutation = trpc.entitlements.ruleset.update.useMutation({
    onSuccess(data) {
      const service = getMetadataServiceById(data.distribution.id)

      form.reset(service.getDefaultValues(data), { keepDefaultValues: false })
      utils.entitlements.ruleset.invalidate()
    },
  })
  const utils = useUtils()

  const handleSubmit = form.handleSubmit(async (values) => {
    const service = getMetadataServiceById(values.distribution.id)
    const invalidMetadataIndexes =
      service.validateRulesetItemMetadataValues(values)

    if (invalidMetadataIndexes.length > 0) {
      invalidMetadataIndexes.forEach((index) => {
        form.setError(`rulesetItems.${index}.metadata`, {
          type: 'manual',
          message: 'Invalid metadata format. Please check your input.',
        })
      })
      return
    }
    values.links?.forEach((link) => {
      const isExisting = initialData.links?.some(({ url }) => url === link.url)

      if (isExisting) {
        return
      }

      link.dateAdded = new Date()
    })

    await notify(updateMutation.mutateAsync(values), {
      progressMessage: `Updating Entitlement Ruleset`,
      successMessage: `Updated Entitlement Ruleset`,
      failureMessage: `Failed to update Entitlement Ruleset`,
    })
  })

  return (
    <Form.Provider {...form}>
      <input {...form.register('id')} hidden />
      <Form.FormStack onSubmit={handleSubmit} submitOnCtrlEnter>
        <Form.SectionTitleRow title="Edit Entitlement Ruleset">
          <Button
            startIcon={<Icons.Save />}
            type="submit"
            variant="contained"
            disabled={updateMutation.isPending || !form.formState.isDirty}
          >
            Update
          </Button>
        </Form.SectionTitleRow>

        <RulesetForm />
      </Form.FormStack>
    </Form.Provider>
  )
}

function useFormInitialData(id: string) {
  const [ruleset] = trpc.entitlements.ruleset.get.useSuspenseQuery({
    id: parseInt(id),
  })

  return ruleset
}
