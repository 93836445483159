import { MultibrandAttributeType } from '@tk/frontend/app/Multibrand/RrnStructureCrud/types'
import { Option } from '@tk/frontend/primitives/forms/SelectField'

export const MULTIBRAND_ATTRIBUTE_TYPE_OPTIONS: Option<MultibrandAttributeType>[] =
  [
    {
      value: 'CURRENCY',
      label: 'Currency',
    },
    {
      value: 'PERIOD',
      label: 'Period',
    },
    {
      value: 'BASIS_CODE',
      label: 'Basis-Code',
    },
    {
      value: 'LOCATION',
      label: 'Location',
    },
    {
      value: 'CLEARING',
      label: 'Clearing',
    },
    {
      value: 'PRICE_TYPE',
      label: 'Price-Type',
    },
    {
      value: 'FREQUENCY',
      label: 'Frequency',
    },
    {
      value: 'COMPANY',
      label: 'Company',
    },
    {
      value: 'SNAP_TIME',
      label: 'Snap Time',
    },
    {
      value: 'STRIKE',
      label: 'Strike',
    },
    {
      value: 'PRODUCT',
      label: 'Product',
    },
  ]

const valueMap = MULTIBRAND_ATTRIBUTE_TYPE_OPTIONS.reduce(
  (map, { value, label }) => {
    map.set(value, label)

    return map
  },
  new Map<MultibrandAttributeType, string>()
)

export function formatTableValue({
  value,
}: {
  value: MultibrandAttributeType
}) {
  return valueMap.get(value) ?? value
}
