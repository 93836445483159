import { useNavigate, useSearchParams } from 'react-router-dom'

import * as Icons from '@mui/icons-material'
import { Button } from '@mui/material'

import { trpc, useUtils } from '@tk/frontend/api'
import {
  getFormComponents,
  usePromiseNotification,
} from '@tk/frontend/primitives'

import { InstrumentForm } from './InstrumentForm'
import { generateInstrumentEditUri } from './StubRouter.routes'
import { InstrumentRequest } from './types'

const Form = getFormComponents<InstrumentRequest>()

export function InstrumentCreatePage() {
  const [searchParams] = useSearchParams()
  const marketGroup = searchParams.get('market-group')
  const form = Form.useForm({
    defaultValues: {
      rrnStructures: [],
      multibrandMarketGroup: {
        id:
          marketGroup && !isNaN(Number(marketGroup))
            ? Number(marketGroup)
            : undefined,
      },
    },
  })

  const navigate = useNavigate()
  const notify = usePromiseNotification()
  const createMutation = trpc.multibrand.instrument.create.useMutation({
    onSuccess(data) {
      navigate({
        pathname: generateInstrumentEditUri(data.id),
      })
    },
  })
  const utils = useUtils()

  const handleSubmit = form.handleSubmit(async (values) => {
    await notify(createMutation.mutateAsync(values), {
      progressMessage: `Creating Market Instrument`,
      successMessage: `Created Market Instrument`,
      failureMessage: `Failed to create Market Instrument`,
    })

    utils.multibrand.invalidate()
  })

  return (
    <Form.Provider {...form}>
      <Form.FormStack onSubmit={handleSubmit} submitOnCtrlEnter>
        <Form.SectionTitleRow title="Create Market Instrument">
          <Button
            startIcon={<Icons.Save />}
            type="submit"
            variant="contained"
            disabled={
              createMutation.isPending ||
              createMutation.isSuccess ||
              !form.formState.isDirty
            }
          >
            Create
          </Button>
        </Form.SectionTitleRow>

        <InstrumentForm />
      </Form.FormStack>
    </Form.Provider>
  )
}
