import * as Icons from '@mui/icons-material'
import { Button } from '@mui/material'
import { useModal } from '@parameta/nice-modal-react'

import { When } from '@tk/frontend/primitives'

import { MutationModal } from './MutationModal'

export function DistributionCreateButton() {
  const modal = useModal(MutationModal)

  return (
    <When can="entitlements.manage">
      <Button
        startIcon={<Icons.Create />}
        variant="contained"
        onClick={() => modal.show({ mode: 'create' })}
      >
        Create
      </Button>
    </When>
  )
}
