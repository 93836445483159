import { useMemo } from 'react'
import { get as getByPath } from 'react-hook-form'

import { Typography } from '@mui/material'

import { ROUTES } from '@tk/frontend/app/routes'
import { AppLink, useFeature } from '@tk/frontend/primitives'
import {
  NameDateCell,
  PairedBusinessObjectsCell,
  PairedDateCell,
  TwoLineTextCell,
  WrappedTextCell,
} from '@tk/frontend/primitives/datagrid/cells'
import { PopoverCell } from '@tk/frontend/primitives/datagrid/cells/PopoverCell'
import {
  createColumns,
  setColumnDefaults,
} from '@tk/frontend/primitives/datagrid/columns'
import { formatStrike } from '@tk/frontend/primitives/presentation-utils'

import { CollectingInChainPopover } from './CollectingInChainPopover'
import {
  RECORD_SCOPE_OPTIONS,
  RECORD_STATUS_OPTIONS,
  RECORD_TYPE_OPTIONS,
} from './common/crud/constants'
import { FilterKey, FILTERS_CONFIG } from './filters-config'
import { LinkedRecordsPopover } from './LinkedRecordsPopover'
import { TouchedByPopover } from './TouchedByPopover'
import { RecordColDef, RecordDto } from './types'
import { sortByKeys } from './useRecordsQueryParams'

export function useRecordColumns() {
  const fidsEnabled = useFeature('fids')

  return useMemo<RecordColDef[]>(() => {
    return createColumns<RecordDto>([
      {
        type: 'string',
        field: 'id',
        headerName: 'ID',
        width: 70,
      },
      {
        type: 'string',
        field: 'name',
        headerName: 'Name',
        width: 300,
        renderCell(params) {
          if (params.row.type === 'CHAIN') {
            return (
              <AppLink
                to={ROUTES.app.recordManagement.defaultRecords({
                  chain: params.row.id,
                })}
              >
                {params.value}
              </AppLink>
            )
          } else {
            return params.value
          }
        },
      },
      {
        type: 'string',
        field: 'source.name',
        headerName: 'Source',
        width: 100,
        renderCell(params) {
          const { source, backbone } = params.row
          return <TwoLineTextCell line1={backbone?.name} line2={source?.name} />
        },
      },
      {
        type: 'string',
        field: 'linkedRecords',
        headerName: 'Linked Collection',
        width: 175,
        renderCell(params) {
          const linkedRecords = params.row.linkedRecords || []
          const collectionCount = linkedRecords.length + 1
          const labelText =
            collectionCount > 1 ? `${collectionCount} Linked Records` : ''

          return (
            <TwoLineTextCell
              line1={
                collectionCount > 1 ? (
                  <Typography
                    variant="body2"
                    color="primary"
                    style={{ cursor: 'pointer', textDecoration: 'underline' }}
                  >
                    <LinkedRecordsPopover
                      id={params.row.id}
                      name={params.row.name}
                      source={params.row.source.name}
                      backbone={params.row.backbone.name}
                      status={params.row.status}
                      validFromDate={params.row.validFromDate}
                      validToDate={params.row.validToDate}
                      createdDate={params.row.createdDate}
                      createdBy={params.row.createdBy}
                      linkedRecords={linkedRecords}
                      linkText={labelText}
                    />
                  </Typography>
                ) : null
              }
              line2={null}
            />
          )
        },
      },
      {
        type: 'string',
        field: 'description',
        headerName: 'Description',
        minWidth: 300,
        renderCell(params) {
          return <WrappedTextCell text={params.value} />
        },
      },
      {
        type: 'string',
        field: 'status',
        headerName: 'Status',
        width: 175,
        renderCell(params) {
          const statusLabel = RECORD_STATUS_OPTIONS.find(
            (option) => option.value === params.value
          )?.label

          const statusSubLabel =
            params.row.parentChains && params.row.parentChains.length > 0 ? (
              <CollectingInChainPopover chains={params.row.parentChains} />
            ) : undefined

          return <TwoLineTextCell line1={statusLabel} line2={statusSubLabel} />
        },
      },
      {
        type: 'string',
        field: 'type',
        headerName: 'Type',
        width: 100,
        valueFormatter(params) {
          return RECORD_TYPE_OPTIONS.find(
            (option) => option.value === params.value
          )?.label
        },
      },
      {
        type: 'string',
        field: 'parentChains',
        headerName: 'Chain',
        width: 150,
        renderCell(params) {
          if (!params.row.parentChains?.length) {
            return ''
          }

          return (
            <div>
              <CollectingInChainPopover chains={params.row.parentChains} />
            </div>
          )
        },
      },

      {
        type: 'string',
        field: 'scope',
        headerName: 'Scope',
        width: 100,
        valueFormatter(params) {
          return RECORD_SCOPE_OPTIONS.find(
            (option) => option.value === params.value
          )?.label
        },
      },
      fidsEnabled
        ? {
            type: 'string',
            field: 'fidGroup.name',
            headerName: 'Field Group',
            width: 100,
            renderCell(params) {
              return (
                <AppLink
                  to={ROUTES.app.fids.groups.defaultFidGroup({
                    filter: params.value,
                  })}
                >
                  {params.value}
                </AppLink>
              )
            },
          }
        : undefined,
      {
        type: 'string',
        field: 'marketGroups',
        headerName: 'Commercial Packages',
        width: 200,
        renderCell(params) {
          const marketGroups = params.row.marketGroups?.filter(
            (mg) => mg.marketCategory?.type === 'COMMERCIAL_PACKAGE'
          )

          return (
            <WrappedTextCell
              text={marketGroups?.map((mg) => mg.description).join(', ')}
            />
          )
        },
      },
      {
        type: 'string',
        field: 'assetType.name',
        headerName: 'Asset Type',
        width: 150,
        renderCell(params) {
          const { assetClass, assetType } = params.row
          return (
            <TwoLineTextCell line1={assetClass?.name} line2={assetType?.name} />
          )
        },
      },
      {
        type: 'string',
        field: 'currency1.name',
        headerName: 'Currencies',
        width: 150,
        renderCell(params) {
          const { currency1, currency2 } = params.row
          return <PairedBusinessObjectsCell dto1={currency1} dto2={currency2} />
        },
      },
      {
        type: 'string',
        field: 'dayCount1.name',
        headerName: 'Day Counts',
        width: 100,
        renderCell(params) {
          const { dayCount1, dayCount2 } = params.row
          return <PairedBusinessObjectsCell dto1={dayCount1} dto2={dayCount2} />
        },
      },
      {
        type: 'string',
        field: 'paymentFrequency1.name',
        headerName: 'Payment Frequencies',
        width: 100,
        renderCell(params) {
          const { paymentFrequency1, paymentFrequency2 } = params.row
          return (
            <PairedBusinessObjectsCell
              dto1={paymentFrequency1}
              dto2={paymentFrequency2}
            />
          )
        },
      },
      {
        type: 'string',
        field: 'strikeUnit',
        headerName: 'Strike',
        width: 100,
        renderCell(params) {
          const { strikeUnit, strikeValue } = params.row
          return (
            <TwoLineTextCell line1={formatStrike(strikeUnit, strikeValue)} />
          )
        },
      },
      {
        type: 'string',
        field: 'settlementIndex1.name',
        headerName: 'Settlement Indexes',
        width: 100,
        renderCell(params) {
          const { settlementIndex1, settlementIndex2 } = params.row
          return (
            <PairedBusinessObjectsCell
              dto1={settlementIndex1}
              dto2={settlementIndex2}
            />
          )
        },
      },
      {
        type: 'string',
        field: 'settlementMethod',
        headerName: 'Settlement Method',
        width: 100,
        renderCell(params) {
          const { settlementMethod } = params.row
          return <PairedBusinessObjectsCell dto1={settlementMethod} />
        },
      },
      {
        type: 'string',
        field: 'period1.name',
        headerName: 'Period 1&2',
        width: 100,
        renderCell(params) {
          const { period1, period2 } = params.row
          return <PairedBusinessObjectsCell dto1={period1} dto2={period2} />
        },
      },
      {
        type: 'string',
        field: 'period3.name',
        headerName: 'Period 3&4',
        width: 100,
        renderCell(params) {
          const { period3, period4 } = params.row
          return <PairedBusinessObjectsCell dto1={period3} dto2={period4} />
        },
      },
      {
        type: 'string',
        field: 'resetFrequency1.name',
        headerName: 'Reset Frequencies',
        width: 100,
        renderCell(params) {
          const { resetFrequency1, resetFrequency2 } = params.row
          return (
            <PairedBusinessObjectsCell
              dto1={resetFrequency1}
              dto2={resetFrequency2}
            />
          )
        },
      },
      {
        type: 'string',
        field: 'location',
        headerName: 'Location',
        width: 150,
        renderCell(params) {
          return (
            <PairedBusinessObjectsCell
              dto1={{
                name: params.row.region?.name,
              }}
              dto2={params.row.location}
            />
          )
        },
      },
      {
        type: 'string',
        field: 'company',
        headerName: 'Company',
        width: 100,
        renderCell(params) {
          return <PairedBusinessObjectsCell dto1={params.value} />
        },
      },
      {
        type: 'string',
        field: 'priceType',
        headerName: 'Price Type',
        width: 100,
        renderCell(params) {
          return <PairedBusinessObjectsCell dto1={params.value} />
        },
      },
      {
        type: 'string',
        field: 'clearingType',
        headerName: 'Clearing Type',
        width: 100,
        renderCell(params) {
          return <PairedBusinessObjectsCell dto1={params.value} />
        },
      },
      {
        type: 'string',
        field: 'pricingFrequency',
        headerName: 'Pricing Frequency',
        width: 100,
        renderCell(params) {
          return <PairedBusinessObjectsCell dto1={params.value} />
        },
      },
      {
        type: 'string',
        field: 'standardDiscounting',
        headerName: 'Standard Discounting',
        width: 100,
        renderCell(params) {
          return <PairedBusinessObjectsCell dto1={params.value} />
        },
      },
      {
        type: 'string',
        field: 'subscription',
        headerName: 'Subscription',
        renderCell(params) {
          const { subscription } = params.row

          return <WrappedTextCell text={subscription?.accountName} />
        },
      },
      {
        type: 'string',
        field: 'notes',
        headerName: 'Notes',
        width: 250,
        renderCell(params) {
          return <PopoverCell text={params.value} />
        },
      },
      {
        type: 'string',
        field: 'validFromDate',
        headerName: 'Valid Dates',
        width: 150,
        renderCell(params) {
          const { validFromDate, validToDate } = params.row
          return (
            <PairedDateCell dateOne={validFromDate} dateTwo={validToDate} />
          )
        },
      },
      {
        type: 'string',
        field: 'modifiedBy',
        headerName: 'Modified',
        width: 150,
        renderCell(params) {
          const { modifiedBy, modifiedDate } = params.row
          return <NameDateCell name={modifiedBy} date={modifiedDate} />
        },
      },
      {
        type: 'string',
        field: 'createdBy',
        headerName: 'Created',
        width: 150,
        renderCell(params) {
          const { createdBy, createdDate } = params.row
          return <NameDateCell name={createdBy} date={createdDate} />
        },
      },
      {
        type: 'string',
        field: 'touchedByNames',
        headerName: 'Projects',
        width: 150,
        renderCell(params) {
          if (!params.row.touchedByNames) {
            return ''
          }

          return (
            <div>
              <TouchedByPopover touchedByNames={params.row.touchedByNames} />
            </div>
          )
        },
      },
    ])
      .map((col) => {
        col.sortable = sortByKeys.includes(col.field as any)
        col.valueGetter ??= (params: {
          row: Record<string, unknown>
          field: string
        }) => {
          return getByPath(params.row, params.field)
        }

        const filterConfig = FILTERS_CONFIG[col.field as FilterKey]
        if (filterConfig) {
          col.filterable = true
          Object.assign(col, filterConfig.colDef)
        }

        return col
      })
      .map(setColumnDefaults)
  }, [fidsEnabled])
}
