import {
  FieldValues,
  FormProvider,
  useController,
  useForm,
} from 'react-hook-form'

import { DateRange } from './DateRange'
import { DatePickerField } from './DateTimePicker'
import { DayPicker } from './DayPicker'
import { Field } from './Field'
import { FieldInfo } from './FieldInfo'
import { FormConfigProvider, useFormContext } from './FormConfigContext'
import { FormStack } from './FormStack'
import { MultiSelectField } from './MultiSelectField'
import { Row } from './Row'
import { Section, SectionTitle } from './Section'
import { SectionBodyGrid } from './SectionBodyGrid'
import { SectionTitleRow } from './SectionTitleRow'
import { SelectField } from './SelectField'
import { Selector } from './Selector'
import { ServersideSelectField } from './ServersideSelectField'
import { TextField } from './TextField'
import { TimePicker } from './TimePicker'
import { getRules } from './validation'

export function getFormComponents<
  FormValues extends FieldValues = FieldValues
>() {
  return {
    useForm: useForm<FormValues>,
    Provider: FormProvider<FormValues>,
    SelectField: SelectField<FormValues>,
    MultiSelectField: MultiSelectField<FormValues>,
    ServersideSelectField: ServersideSelectField<FormValues>,
    TextField: TextField<FormValues>,
    FormStack: FormStack,
    Selector: Selector,
    Section: Section,
    DateRange: DateRange<FormValues>,
    DatePickerField: DatePickerField<FormValues>,
    TimePicker: TimePicker<FormValues>,
    DayPicker: DayPicker,
    SectionTitle: SectionTitle,
    SectionBodyGrid: SectionBodyGrid,
    SectionTitleRow: SectionTitleRow,
    Row: Row,
    Field: Field<FormValues>,
    FieldInfo: FieldInfo,
    rules: getRules(),
    FormConfigProvider: FormConfigProvider,
    useFormContext: useFormContext,
    useController: useController<FormValues>,
  }
}
