import { z } from 'zod'

import { EntitlementRulesetDto } from '@lib/pgs'

import { createJsonParser } from './createJsonParser'
import { TripServerForm } from './TripServerForm'
import {
  DistributionService,
  RulesetItemRequestDto,
  RulesetRequestDtoWithItems,
  TripServerFields,
} from './types'

export type { TripServerFields }

const configurationSchema = z.object({
  clientRegionalOrigin: z.string(),
})

type ConfigurationSchema = z.infer<typeof configurationSchema>

const parser = createJsonParser<ConfigurationSchema>(configurationSchema)

export class TripServerService
  implements DistributionService<TripServerFields>
{
  private readonly createMetadataString = (
    values: Partial<RulesetItemRequestDto<TripServerFields>>
  ) => {
    return JSON.stringify({
      clientRegionalOrigin: values.clientRegionalOrigin,
    })
  }

  private readonly validateMetadata = (metadata: string) => {
    try {
      parser(metadata)

      return true
    } catch {
      console.error('Error parsing metadata')
      return false
    }
  }

  getDefaultValues = (data: EntitlementRulesetDto) => {
    const updatedData = data as Partial<
      RulesetRequestDtoWithItems<TripServerFields>
    >

    if (!updatedData.rulesetItems) {
      return updatedData
    }

    updatedData.rulesetItems =
      updatedData.rulesetItems?.map((rulesetItem) => {
        try {
          const parsed = parser(rulesetItem.metadata ?? '')

          return { ...rulesetItem, ...parsed }
        } catch {
          console.error(
            `Error parsing metadata for ruleset item "${rulesetItem.name}" to retrieve default values`
          )
          return rulesetItem
        }
      }) ?? []

    return updatedData
  }

  validateRulesetItemMetadataValues = (
    values: Partial<RulesetRequestDtoWithItems<TripServerFields>>
  ) => {
    const invalidIndexes: number[] = []

    if (values.rulesetItems && values.rulesetItems.length > 0) {
      values.rulesetItems.forEach((rulesetItem, index) => {
        const metadata = this.createMetadataString(rulesetItem)
        const validMetadata = this.validateMetadata(metadata ?? '')

        if (!validMetadata) {
          invalidIndexes.push(index)
          return
        }

        rulesetItem.metadata = metadata
      })
    }

    return invalidIndexes
  }

  MetadataForm = TripServerForm
}
