export const REPORT_TYPE_OPTIONS = [
  {
    value: 'ALL_TICKS',
    label: 'All ticks',
  },
  {
    value: 'HOURLY_SNAPS',
    label: 'Hourly snaps',
  },
  {
    value: 'DAILY_SNAP',
    label: 'Daily snap',
  },
]
