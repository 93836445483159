import { useMemo } from 'react'

import { Box, Typography } from '@mui/material'
import { DateTime } from 'luxon'

export type PairedDateCellProps = {
  dateOne: string | Date | null | undefined
  dateTwo: string | Date | null | undefined
}

const formatDate = (date: string | Date) => {
  const luxDate =
    typeof date === 'string'
      ? DateTime.fromISO(date)
      : DateTime.fromJSDate(date)

  const timePart = luxDate.toFormat('HH:mm')
  const datePart = luxDate.toFormat('dd MMM yyyy')

  return `${timePart} ${datePart}`
}

export function PairedDateCell({
  dateOne,
  dateTwo,
}: Readonly<PairedDateCellProps>) {
  const fmtDateOne = useMemo(
    () => (dateOne ? formatDate(dateOne) : '-'),
    [dateOne]
  )
  const fmtDateTwo = useMemo(
    () => (dateTwo ? formatDate(dateTwo) : '-'),
    [dateTwo]
  )

  if (!dateOne && !dateTwo) {
    return <></>
  }

  return (
    <Box textAlign="center">
      <Typography variant="body2" display="block">
        {fmtDateOne}
      </Typography>
      <Typography variant="body2" display="block">
        {fmtDateTwo}
      </Typography>
    </Box>
  )
}

export default PairedDateCell
