import { useMemo } from 'react'

import * as Icons from '@mui/icons-material'
import { Box, TextField } from '@mui/material'
import { DataGridPremium } from '@mui/x-data-grid-premium'

import { trpc } from '@tk/frontend/api'
import { ButtonLink } from '@tk/frontend/primitives'
import {
  ActionButtonGroup,
  defaultProps,
  GridEnrichedColDef,
} from '@tk/frontend/primitives/datagrid'
import { NameDateCell } from '@tk/frontend/primitives/datagrid/cells'
import { setColumnDefaults } from '@tk/frontend/primitives/datagrid/columns'
import { useClientFilter } from '@tk/frontend/primitives/datagrid/useClientFilter'

import { generateRrnStructureEditUri } from './RrnStructureRouter.routes'
import { RrnStructureListDto } from './types'

export function RrnStructureCrudTable() {
  const [list] = trpc.multibrand.rrnStructure.list.useSuspenseQuery()

  const cols = useMemo<GridEnrichedColDef<RrnStructureListDto>[]>(() => {
    const columns: GridEnrichedColDef<RrnStructureListDto>[] = [
      {
        type: 'string',
        field: 'name',
        headerName: 'Name',
        flex: 1.5,
      },
      {
        type: 'string',
        field: 'description',
        headerName: 'Description',
        flex: 1,
      },
      {
        type: 'string',
        field: 'retiredAt',
        headerName: 'Status',
        width: 80,
        valueGetter: ({ value }) => {
          return value ? 'Retired' : 'Active'
        },
      },
      {
        type: 'number',
        field: 'marketInstrumentCount',
        headerName: 'Related Stubs',
        width: 100,
      },
      {
        type: 'string',
        field: 'createdBy',
        headerName: 'Created',
        width: 150,
        editable: false,
        renderCell: ({ row }) => {
          const { createdBy, createdDate } = row
          return <NameDateCell name={createdBy} date={createdDate} />
        },
      },
      {
        type: 'string',
        field: 'modifiedBy',
        headerName: 'Modified',
        width: 150,
        editable: false,
        renderCell: ({ row }) => {
          const { modifiedBy, modifiedDate } = row
          return <NameDateCell name={modifiedBy} date={modifiedDate} />
        },
      },
      {
        type: 'actions',
        field: 'actions',
        headerName: '',
        width: 85,
        align: 'right',
        sortable: false,
        getActions: ({ id }) => [
          <ActionButtonGroup key="actions">
            <ButtonLink
              variant="text"
              startIcon={<Icons.Edit />}
              to={generateRrnStructureEditUri(id)}
            >
              Edit
            </ButtonLink>
          </ActionButtonGroup>,
        ],
      },
    ]
    return columns.map((col) => {
      col.filterable ??= true
      col.sortable ??= true
      setColumnDefaults(col)
      return col
    })
  }, [])

  const { filteredList, filterValue, setFilterValue } = useClientFilter(list)

  return (
    <Box display="flex" flexDirection="column" height="100%" width="100%">
      <TextField
        value={filterValue ?? ''}
        onChange={(e) => setFilterValue(e.target.value)}
        label="Filter"
        variant="filled"
        inputProps={{
          role: 'search',
        }}
        placeholder='You may use wildcards (%) to search for partial Name and Description. ie. "%ICAP%"'
        fullWidth
      />

      <Box flex="1 1 0" minHeight={0} minWidth={0}>
        <DataGridPremium
          {...defaultProps}
          rows={filteredList}
          columns={cols}
          density="compact"
          initialState={{
            sorting: {
              sortModel: [{ field: 'name', sort: 'asc' }],
            },
          }}
        />
      </Box>
    </Box>
  )
}
