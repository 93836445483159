import { useMemo } from 'react'

import * as Icons from '@mui/icons-material'
import { Box, Button, TextField } from '@mui/material'
import { DataGridPremium } from '@mui/x-data-grid-premium'
import { useModal } from '@parameta/nice-modal-react'

import { Outputs, trpc } from '@tk/frontend/api'
import {
  ActionButtonGroup,
  defaultProps,
  GridEnrichedColDef,
} from '@tk/frontend/primitives/datagrid'
import { NameDateCell } from '@tk/frontend/primitives/datagrid/cells'
import { setColumnDefaults } from '@tk/frontend/primitives/datagrid/columns'
import { useClientFilter } from '@tk/frontend/primitives/datagrid/useClientFilter'

import { MutationModal } from './MutationModal'

type DistributionType = Outputs['entitlements']['distribution']['get']

export function DistributionCrudTable() {
  const [list] = trpc.entitlements.distribution.list.useSuspenseQuery()

  const modal = useModal(MutationModal)

  const cols = useMemo<GridEnrichedColDef<DistributionType>[]>(() => {
    const columns: GridEnrichedColDef<DistributionType>[] = [
      {
        type: 'string',
        field: 'id',
        headerName: 'ID',
        width: 70,
        editable: false,
      },
      {
        type: 'string',
        field: 'name',
        headerName: 'Name',
        flex: 1,
        editable: false,
      },
      {
        type: 'string',
        field: 'description',
        headerName: 'Description',
        flex: 1,
      },
      {
        type: 'string',
        field: 'createdBy',
        headerName: 'Created',
        width: 150,
        editable: false,
        renderCell(params) {
          const { createdBy, createdDate } = params.row
          return <NameDateCell name={createdBy} date={createdDate} />
        },
      },
      {
        type: 'string',
        field: 'modifiedBy',
        headerName: 'Modified',
        width: 150,
        editable: false,
        renderCell(params) {
          const { modifiedBy, modifiedDate } = params.row
          return <NameDateCell name={modifiedBy} date={modifiedDate} />
        },
      },
      {
        type: 'actions',
        field: 'actions',
        headerName: '',
        width: 85,
        cellClassName: 'actions',
        align: 'right',
        getActions: ({ row }) => {
          return [
            <ActionButtonGroup key="actions">
              <Button
                startIcon={<Icons.Edit />}
                onClick={() => modal.show({ mode: 'edit', initialValues: row })}
                color="primary"
              >
                Edit
              </Button>
            </ActionButtonGroup>,
          ]
        },
      },
    ]
    return columns.map((col) => {
      col.filterable ??= true
      col.sortable ??= true
      setColumnDefaults(col)
      return col
    })
  }, [modal])
  const { filteredList, filterValue, setFilterValue } = useClientFilter(list)

  return (
    <Box display="flex" flexDirection="column" height="100%" width="100%">
      <TextField
        value={filterValue ?? ''}
        onChange={(e) => setFilterValue(e.target.value)}
        label="Filter"
        variant="filled"
        inputProps={{
          role: 'search',
        }}
        placeholder='You may use wildcards (%) to search for partial Name and Description. ie. "%ICAP%"'
        fullWidth
      />

      <Box flex="1 1 0" minHeight={0} minWidth={0}>
        <DataGridPremium
          {...defaultProps}
          rows={filteredList}
          columns={cols}
          density="compact"
          initialState={{
            sorting: {
              sortModel: [
                { field: 'type', sort: 'asc' },
                { field: 'name', sort: 'asc' },
              ],
            },
          }}
        />
      </Box>
    </Box>
  )
}
