import { Stack } from '@mui/material'
import { DateTime } from 'luxon'

import { getFormComponents } from '@tk/frontend/primitives'
import { TIMEZONE_OPTIONS } from '@tk/frontend/primitives/timezoneOptions'

import {
  DeliveryHubFields,
  MetadataFormProps,
  RulesetRequestDtoWithItems,
} from './types'

const Form = getFormComponents<RulesetRequestDtoWithItems<DeliveryHubFields>>()

const scheduleOptions = [
  { value: 'Monday', label: 'Monday' },
  { value: 'Tuesday', label: 'Tuesday' },
  { value: 'Wednesday', label: 'Wednesday' },
  { value: 'Thursday', label: 'Thursday' },
  { value: 'Friday', label: 'Friday' },
  { value: 'Saturday', label: 'Saturday' },
  { value: 'Sunday', label: 'Sunday' },
]

const generateTimeOptions = () => {
  const times = []
  for (let hour = 0; hour < 24; hour++) {
    times.push(DateTime.utc().set({ hour, minute: 0 }))
    times.push(DateTime.utc().set({ hour, minute: 30 }))
  }
  return times.map((dateTime) => ({
    label: dateTime.toFormat('HH:mm'),
    value: dateTime.toFormat('HH:mm'),
  }))
}

const timeOptions = generateTimeOptions()

export function DeliveryHubForm({ index }: Readonly<MetadataFormProps>) {
  return (
    <Stack direction="row">
      <Form.Field
        name={`rulesetItems.${index}.time`}
        label="Time (Required)"
        input={
          <Form.MultiSelectField
            options={timeOptions}
            rules={Form.rules.required}
            placeholder="Select multiple times"
          />
        }
      />
      <Form.Field
        name={`rulesetItems.${index}.schedule`}
        label="Schedule (Required)"
        input={
          <Form.MultiSelectField
            options={scheduleOptions}
            rules={Form.rules.required}
            placeholder="Select multiple days"
            sort={false}
          />
        }
      />
      <Form.Field
        name={`rulesetItems.${index}.timezone`}
        label="Timezone (Required)"
        input={
          <Form.SelectField
            options={TIMEZONE_OPTIONS}
            rules={Form.rules.required}
          />
        }
      />
    </Stack>
  )
}
