import * as Icons from '@mui/icons-material'

import { ButtonLink } from '@tk/frontend/primitives'
import { ActionButtonGroup } from '@tk/frontend/primitives/datagrid'

import { generateInstrumentEditUri } from './StubRouter.routes'
import { InstrumentListItem } from './types'

export type Props = {
  data: InstrumentListItem
}

export function InstrumentActions({ data }: Props) {
  return (
    <ActionButtonGroup>
      <ButtonLink
        startIcon={<Icons.Edit />}
        to={generateInstrumentEditUri(data.id)}
        variant="text"
        color="secondary"
      >
        Edit
      </ButtonLink>
    </ActionButtonGroup>
  )
}
