import { RrnStructureFormData, RrnStructureRequestDto } from './types'

export function transformFromFormData(formData: RrnStructureFormData) {
  const requestData = formData as RrnStructureRequestDto

  if (formData.status === 'retired' && !requestData.retiredAt) {
    requestData.retiredAt = new Date()
  } else if (formData.status === 'active' && requestData.retiredAt) {
    requestData.retiredAt = undefined
  }

  return requestData
}
