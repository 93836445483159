import { useCallback } from 'react'

import { useEntitlementLists } from '@tk/frontend/app/Entitlements/Ruleset/useEntitlementList'

import { DefaultService } from './defaultService'
import { DeliveryHubService } from './deliveryHubDistributionService'
import { TripServerService } from './tripServerDistributionService'
import { CustomMetadataDistributionName, DistributionService } from './types'

const serviceMap: Record<
  CustomMetadataDistributionName,
  DistributionService<any>
> = {
  'delivery-hub': new DeliveryHubService(),
  'trip-server': new TripServerService(),
}

const defaultService = new DefaultService()

export const getMetadataServiceByName = (name?: string) => {
  return serviceMap[name as CustomMetadataDistributionName] ?? defaultService
}

export const useMetadataServiceLocator = () => {
  const { distributions } = useEntitlementLists()

  const getMetadataServiceById = useCallback(
    (distributionId?: number) => {
      if (!distributionId) {
        return defaultService
      }

      const distribution = distributions.find(({ id }) => id === distributionId)
      if (!distribution?.name) {
        return defaultService
      }

      return getMetadataServiceByName(distribution.name)
    },
    [distributions]
  )

  return {
    getMetadataServiceById,
    getMetadataServiceByName,
  }
}
