import { getFormComponents } from '@tk/frontend/primitives'

import {
  MetadataFormProps,
  RulesetRequestDtoWithItems,
  TripServerFields,
} from './types'

const Form = getFormComponents<RulesetRequestDtoWithItems<TripServerFields>>()

const originOptions = [
  {
    value: 'APAC',
    label: 'APAC',
  },
  {
    value: 'AMER',
    label: 'AMER',
  },
  {
    value: 'EMEA',
    label: 'EMEA',
  },
]

export function TripServerForm({ index }: Readonly<MetadataFormProps>) {
  return (
    <Form.Field
      name={`rulesetItems.${index}.clientRegionalOrigin`}
      label="Client Regional Origin (Required)"
      input={
        <Form.SelectField options={originOptions} rules={Form.rules.required} />
      }
    />
  )
}
