import { useCallback, useMemo } from 'react'
import { useFieldArray, useFormContext } from 'react-hook-form'

import { Add } from '@mui/icons-material'
import {
  Button,
  FormLabel,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from '@mui/material'

import { getFormComponents } from '@tk/frontend/primitives'

import { InstrumentRequest } from './types'
import { useMarketGroupOptions } from './useMarketGroupOptions'
import { useRrnStructureOptions } from './useRrnStructureOptions'

const Form = getFormComponents<InstrumentRequest>()

export function InstrumentForm() {
  const form = useFormContext<InstrumentRequest>()
  const rrnStructures = useFieldArray({
    control: form.control,
    name: 'rrnStructures',
    keyName: 'formId',
  })
  const { marketGroupOptions, marketGroups } = useMarketGroupOptions()
  const marketGroupId = form.watch('multibrandMarketGroup.id')

  const marketGroupName = useMemo(() => {
    return (
      marketGroups.find(({ id }) => id === marketGroupId)?.name ?? marketGroupId
    )
  }, [marketGroupId, marketGroups])

  const name = form.watch('name')

  const rrnStructureOptions = useRrnStructureOptions()

  const addRrnStructure = useCallback(() => {
    rrnStructures.append({
      id: -1,
    })
  }, [rrnStructures])

  const removeRrnStructure = useCallback(
    (index: number) => {
      rrnStructures.remove(index)
    },
    [rrnStructures]
  )

  return (
    <Form.SectionBodyGrid>
      <Form.Section title="Details">
        <Form.Field
          label="Name (Required)"
          name="name"
          input={<Form.TextField autoFocus rules={Form.rules.required} />}
        />
        <Form.Field
          label="Description (Required)"
          name="description"
          input={<Form.TextField rules={Form.rules.required} />}
        />

        <Form.Field
          label="Market Group (Required)"
          name="multibrandMarketGroup.id"
          input={
            <Form.SelectField
              options={marketGroupOptions}
              rules={Form.rules.required}
            />
          }
        />
        <div>
          <FormLabel>Stub Name</FormLabel>
          <Typography sx={{ fontSize: '0.8rem' }}>
            {marketGroupName + name}
          </Typography>
        </div>
      </Form.Section>
      <Form.Section title="Root Record Name Structures" gridColumns={12}>
        <Table size="small">
          <TableBody>
            {rrnStructures.fields.map(({ formId }, index) => (
              <TableRow key={formId}>
                <TableCell>
                  <Form.Field
                    name={`rrnStructures.${index}.id`}
                    input={
                      <Form.SelectField options={rrnStructureOptions} group />
                    }
                  />
                </TableCell>
                <TableCell sx={{ width: '4rem' }}>
                  <Button
                    variant="text"
                    color="warning"
                    onClick={() => removeRrnStructure(index)}
                  >
                    Delete
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <Button fullWidth startIcon={<Add />} onClick={addRrnStructure}>
          Add
        </Button>
      </Form.Section>
    </Form.SectionBodyGrid>
  )
}
