import { PageContentLayout } from '@tk/frontend/primitives/layout'

import { RrnStructureCreateButton } from './RrnStructureCreateButton'
import { RrnStructureCrudTable } from './RrnStructureCrudTable'

export function RrnStructureCrud() {
  return (
    <PageContentLayout
      title="Root Record Name Structures"
      controls={<RrnStructureCreateButton />}
    >
      <RrnStructureCrudTable />
    </PageContentLayout>
  )
}
