import { useNavigate } from 'react-router-dom'

import * as Icons from '@mui/icons-material'
import { Button } from '@mui/material'

import { ROUTES } from '@tk/frontend/app/routes'
import { When } from '@tk/frontend/primitives'

export function RulesetCreateButton() {
  const navigate = useNavigate()

  return (
    <When can="entitlements.manage">
      <Button
        startIcon={<Icons.Create />}
        variant="contained"
        onClick={() => navigate(ROUTES.app.entitlements.ruleset.create)}
      >
        Create
      </Button>
    </When>
  )
}
