import { useCallback, useMemo } from 'react'

import { Button, Stack } from '@mui/material'
import slugify from 'slugify'

import { Inputs, trpc, useUtils } from '@tk/frontend/api'
import {
  getFormComponents,
  usePromiseNotification,
} from '@tk/frontend/primitives'

import { VisibilitySelector } from './VisibilitySelector'

export type DistributionFormValues =
  Inputs['entitlements']['distribution']['create'] & { id: number }

const Form = getFormComponents<DistributionFormValues>()

export type DistributionFormProps = {
  onFinished?: () => void
  initialValues?: DistributionFormValues
  mode: 'create' | 'edit'
}

export function DistributionForm({
  onFinished,
  initialValues,
  mode,
}: Readonly<DistributionFormProps>) {
  const form = Form.useForm({
    defaultValues: {
      enableFields: false,
      enableAccessDates: false,
      ...initialValues,
    },
  })
  const { handleSubmit: _handleSubmit, setValue } = form

  const progress = usePromiseNotification()
  const mutation = useMutation(mode)

  const handleNameBlur = useCallback(
    (event: React.FocusEvent<HTMLInputElement>) => {
      const value = event.target.value
      const kebabName = slugify(value, { lower: true, strict: true })
      setValue('name', kebabName, { shouldValidate: true })
    },
    [setValue]
  )

  const handleSubmit = useMemo(() => {
    return _handleSubmit(async (values) => {
      const promise = mutation.mutateAsync(values)
      await progress(promise, {
        progressMessage: mode === 'create' ? 'Creating' : 'Updating',
        successMessage: mode === 'create' ? 'Created' : 'Updated',
        failureMessage: `Error ${mode === 'create' ? 'creating' : 'updating'}`,
      })

      onFinished?.()
    })
  }, [_handleSubmit, mode, mutation, onFinished, progress])

  return (
    <Form.Provider {...form}>
      <Stack width="30rem" component="form" onSubmit={handleSubmit}>
        <Form.Field
          disabled={mode === 'edit'}
          label="Name (Required)"
          name="name"
          input={
            <Form.TextField
              autoFocus
              rules={Form.rules.required}
              onBlur={handleNameBlur}
            />
          }
        />
        <Form.Field
          label="Description (Required)"
          name="description"
          input={<Form.TextField rules={Form.rules.required} />}
        />

        <VisibilitySelector
          label="Field Configuration (Required)"
          name="enableFields"
          infoText="Controls whether field configuration is required when creating a related ruleset"
        />

        <VisibilitySelector
          label="Access Dates (Required)"
          name="enableAccessDates"
          infoText="Controls whether access date inputs are visible when creating a related ruleset"
          trueLabel="Visible"
        />

        <Form.Row>
          <Button type="submit">
            {mode === 'create' ? 'Create' : 'Update'}
          </Button>
        </Form.Row>
      </Stack>
    </Form.Provider>
  )
}

function useMutation(mode: 'create' | 'edit') {
  const utils = useUtils()

  const { useMutation } =
    mode === 'create'
      ? trpc.entitlements.distribution.create
      : trpc.entitlements.distribution.update

  return useMutation({
    onSuccess() {
      utils.entitlements.distribution.invalidate()
    },
  })
}
