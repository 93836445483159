import { useMemo } from 'react'

import { trpc } from '@tk/frontend/api'
import { labelify } from '@tk/frontend/primitives'
import { Option } from '@tk/frontend/primitives/forms/SelectField'

export function useMarketGroupOptions() {
  const [marketGroups] = trpc.multibrand.marketGroup.list.useSuspenseQuery()

  const marketGroupOptions = useMemo(() => {
    return marketGroups.map<Option>(({ id, name, description }) => {
      return {
        label: labelify(name, description),
        value: id,
      }
    })
  }, [marketGroups])

  return { marketGroups, marketGroupOptions }
}
