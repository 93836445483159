import * as Icons from '@mui/icons-material'
import { Button } from '@mui/material'
import { useModal } from '@parameta/nice-modal-react'

import * as routes from '@tk/frontend/app/routes'
import { ButtonLink } from '@tk/frontend/primitives'
import { ActionButtonGroup } from '@tk/frontend/primitives/datagrid'

import { MutationModal } from './MutationModal'
import { MarketGroup } from './types'

export type Props = {
  data: MarketGroup
}

export function MarketGroupActions({ data }: Props) {
  const modal = useModal(MutationModal)

  return (
    <ActionButtonGroup>
      <ButtonLink
        color="primary"
        startIcon={<Icons.Add />}
        variant="text"
        to={{
          pathname: routes.app.multibrand.stubs.createInstrument,
          search: `market-group=${data.id}`,
        }}
      >
        Add
      </ButtonLink>
      <Button
        startIcon={<Icons.Edit />}
        onClick={() => {
          modal.show({
            data: data,
          })
        }}
      >
        Edit
      </Button>
    </ActionButtonGroup>
  )
}
