import { useMemo } from 'react'

import { trpc } from '@tk/frontend/api'

import { RulesetItemRowType, RulesetRowType, UnionRow } from './types'

const getTreeDataPath = (row: UnionRow) => {
  return row.path
}

const getRowId = (row: UnionRow) => {
  return row.id.toString()
}

export function useRows() {
  const [list] = trpc.entitlements.ruleset.list.useSuspenseQuery()

  const allRows: UnionRow[] = useMemo(() => {
    return list.flatMap((ruleset) => {
      return [
        {
          ...ruleset,
          _type: 'ruleset' as RulesetRowType,
          path: [ruleset.name],
        },
        ...(ruleset.rulesetItems?.map((rulesetItem) => {
          return {
            ...rulesetItem,
            _type: 'rulesetItem' as RulesetItemRowType,
            id: ruleset.id * 1_000_000 + rulesetItem.id,
            path: [ruleset.name, rulesetItem.name],
          }
        }) ?? []),
      ]
    })
  }, [list])

  return {
    rows: allRows,
    getTreeDataPath,
    getRowId,
  }
}
