import { useMemo } from 'react'

import { trpc } from '@tk/frontend/api'
import { Option } from '@tk/frontend/primitives/forms/SelectField'

export function useEntitlementLists() {
  const [[distributions, actorList, marketGroupList]] = trpc.useSuspenseQueries(
    (t) => {
      return [
        t.entitlements.distribution.list(),
        t.entitlements.actor.list(undefined, {
          select(data) {
            return data.map<Option<number>>((item) => {
              return {
                label: item.name,
                value: item.id,
              } as Option
            })
          },
        }),
        t.commercialPackages.ui.listPackages(),
      ]
    }
  )

  const distributionList = useMemo(() => {
    return distributions.map<Option<number>>(({ id, description, name }) => {
      return {
        label: description ?? name,
        value: id,
      }
    })
  }, [distributions])

  return { distributions, distributionList, actorList, marketGroupList }
}
