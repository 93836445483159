import { useNavigate } from 'react-router-dom'

import * as Icons from '@mui/icons-material'
import { Button } from '@mui/material'

import { trpc, useUtils } from '@tk/frontend/api'
import {
  getFormComponents,
  usePromiseNotification,
} from '@tk/frontend/primitives'

import {
  RulesetCreateDto,
  useMetadataServiceLocator,
} from './metadata-services'
import { generateRulesetEditUri } from './Ruleset.routes'
import { RulesetForm } from './RulesetForm'

const Form = getFormComponents<RulesetCreateDto>()

export function RulesetCreatePage() {
  const form = Form.useForm({
    defaultValues: {},
  })

  const { getMetadataServiceById } = useMetadataServiceLocator()
  const navigate = useNavigate()
  const notify = usePromiseNotification()

  const createMutation = trpc.entitlements.ruleset.create.useMutation({
    onSuccess(data) {
      navigate({
        pathname: generateRulesetEditUri(data.id),
      })

      const service = getMetadataServiceById(data.distribution.id)

      form.reset(service.getDefaultValues(data), {
        keepDefaultValues: false,
      })

      utils.entitlements.ruleset.invalidate()
    },
  })
  const utils = useUtils()

  const handleSubmit = form.handleSubmit(async (values) => {
    const service = getMetadataServiceById(values.distribution.id)
    const invalidMetadataIndexes =
      service.validateRulesetItemMetadataValues(values)

    if (invalidMetadataIndexes.length > 0) {
      invalidMetadataIndexes.forEach((index) => {
        form.setError(`rulesetItems.${index}.metadata`, {
          type: 'manual',
          message: 'Invalid metadata format. Please check your input.',
        })
      })
      return
    }

    values.links?.forEach((link) => {
      link.dateAdded = new Date()
    })

    await notify(createMutation.mutateAsync(values), {
      progressMessage: `Creating Entitlement Ruleset`,
      successMessage: `Created Entitlement Ruleset`,
      failureMessage: `Failed to create Entitlement Ruleset`,
    })
  })

  return (
    <Form.Provider {...form}>
      <Form.FormStack onSubmit={handleSubmit} submitOnCtrlEnter>
        <Form.SectionTitleRow title="Create Entitlement Ruleset">
          <Button
            startIcon={<Icons.Save />}
            type="submit"
            variant="contained"
            disabled={
              createMutation.isPending ||
              createMutation.isSuccess ||
              !form.formState.isDirty
            }
          >
            Create
          </Button>
        </Form.SectionTitleRow>

        <RulesetForm />
      </Form.FormStack>
    </Form.Provider>
  )
}
