import { useLayoutEffect, useMemo, useRef } from 'react'
import {
  FieldValues,
  Path,
  useController,
  UseControllerProps,
} from 'react-hook-form'

import {
  Autocomplete,
  Chip,
  createFilterOptions,
  FilterOptionsState,
  Popper,
  TextField,
  TextFieldProps,
} from '@mui/material'
import _ from 'lodash'

export type Option<T = any> = {
  group?: string
  label: string
  value: T
}

export type InputValue = FilterOptionsState<Option<FieldValues>>

export interface MultiSelectFieldProps<
  FormValues extends FieldValues = FieldValues
> {
  name?: Path<FormValues>
  label?: string
  placeholder?: string
  options?: Option[]
  width?: string
  fullWidth?: boolean
  variant?: TextFieldProps['variant']
  rules?: UseControllerProps<FormValues>['rules']
  group?: boolean
  autoFocus?: boolean
  disabled?: boolean
  freeSolo?: boolean
  customFilterFunction?: (options: Option[], inputValue: InputValue) => Option[]
  onValueChange?: (newValue: any) => void
  sort?: boolean
}

const EMPTY_OPTION: Option = {
  label: '[None]',
  value: '',
  group: '',
}

const isOptionEqual = (option: Option, value: Option) => {
  return option.value === value.value
}

function coerce(value: string | Option | undefined): Option | undefined {
  return typeof value === 'string' ? { label: value, value: value } : value
}

export function MultiSelectField<FormValues extends FieldValues = FieldValues>(
  props: Readonly<MultiSelectFieldProps<FormValues>>
) {
  const { field, fieldState } = useController<FormValues>({
    name: props.name ?? ('' as any),
    rules: props.rules,
  })

  const options = useMemo(() => {
    const opts = props.freeSolo ? [] : [EMPTY_OPTION]

    return props.sort
      ? opts.concat(
          _.sortBy(props.options ?? [], (item) => [item.group, item.label])
        )
      : props.options ?? []
  }, [props.freeSolo, props.options, props.sort])

  const currentOptions = useMemo(() => {
    const fallback = props.freeSolo
      ? {
          label: field.value,
          value: field.value,
        }
      : EMPTY_OPTION

    return (field.value ?? []).map(
      (val: any) => options.find((opt) => opt.value === val) ?? fallback
    )
  }, [field.value, options, props.freeSolo])

  const errorMessage = fieldState.error?.message

  const inputRef = useRef<any>()
  const focus = useRef(props.autoFocus)
  useLayoutEffect(() => {
    if (focus.current) {
      inputRef.current?.focus?.()
    }
  }, [])

  const filterOptions = useMemo(() => {
    if (props.customFilterFunction) {
      return (
        options: Option<FormValues>[],
        state: FilterOptionsState<Option<FormValues>>
      ) => {
        return props.customFilterFunction!(options, state as InputValue)
      }
    } else {
      // If no custom filter function is provided, use the default
      return createFilterOptions<Option<FormValues>>({
        limit: 100,
        trim: true,
      })
    }
  }, [props.customFilterFunction])

  return (
    <Autocomplete
      multiple
      size="small"
      sx={{
        width: props.width ?? (props.fullWidth ? 'auto' : '7rem'),
      }}
      disableClearable
      options={options}
      groupBy={
        props.group ? (option) => option.group ?? 'Ungrouped' : undefined
      }
      getOptionLabel={(option) => coerce(option)?.label ?? ''}
      PopperComponent={SelectPopper}
      isOptionEqualToValue={isOptionEqual}
      {...field}
      selectOnFocus
      fullWidth={props.fullWidth}
      value={currentOptions}
      onChange={(e, items, reason) => {
        const newValue = items.map((item) => coerce(item)?.value)
        field.onChange(newValue)
        props.onValueChange?.(newValue)
      }}
      onInputChange={(ev, val, reason) => {
        if (!props.freeSolo) {
          // In normal selector mode we don't want to propagate intermediate values
          return
        }
        if (reason === 'reset') {
          return
        }

        field.onChange(val)
      }}
      freeSolo={props.freeSolo}
      renderOption={(props, option) => {
        return (
          <li {...props} key={option.value.toString()}>
            {option.label}
          </li>
        )
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          InputProps={{
            name: props.name,
            ...params.InputProps,
          }}
          label={props.label}
          variant={props.variant ?? 'standard'}
          size="small"
          placeholder={props.placeholder}
          error={!!errorMessage}
          helperText={errorMessage}
          autoFocus={props.autoFocus}
          inputRef={inputRef}
          sx={{
            '.MuiFormHelperText-filled': {
              margin: 0,
            },
          }}
        />
      )}
      disabled={props.disabled}
      filterOptions={filterOptions}
      renderTags={(value, getTagProps) => {
        return value.map((option, index) => {
          const { key, ...props } = getTagProps({ index })
          return <Chip key={key} size="small" {...props} label={option.label} />
        })
      }}
      disableCloseOnSelect
    />
  )
}

const SelectPopper = function (props: any) {
  return (
    <Popper
      {...props}
      style={{ width: 'auto', maxWidth: '30rem' }}
      placement="bottom-start"
    />
  )
}
