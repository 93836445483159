import { useMemo } from 'react'

import { Dialog, DialogContent, DialogTitle } from '@mui/material'
import { create, useModal } from '@parameta/nice-modal-react'

import { MarketGroupForm } from './MarketGroupForm'
import { MarketGroup } from './types'

type MarketGroupModel = {
  data: Partial<MarketGroup>
}

export const MutationModal = create<MarketGroupModel>((model) => {
  const modal = useModal()
  const title = useMemo(
    () => (!model.data.id ? 'New Market Group' : 'Edit Market Group'),
    [model.data.id]
  )

  return (
    <Dialog open={modal.visible} onClose={modal.hide}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <MarketGroupForm initialValues={model.data} onFinished={modal.hide} />
      </DialogContent>
    </Dialog>
  )
})
