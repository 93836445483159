import { DateTime } from 'luxon'

import { Inputs, Outputs } from '@tk/frontend/api'
import { getFormComponents } from '@tk/frontend/primitives/forms'

export type CreateExtractaMutationInputs = Inputs['extracta']['create']
export type CreateExtractaFormValues = Omit<
  CreateExtractaMutationInputs,
  'startDate' | 'endDate' | 'snapTime'
> & {
  dateRange: [DateTime | null, DateTime | null] | null
  snapTime?: DateTime | null
  startTime?: DateTime | null
  endTime?: DateTime | null
  excludedDays?: Array<number>
}

export const Form = getFormComponents<CreateExtractaFormValues>()

export type Fids = Outputs['extracta']['getExtractableRecordsAndFids']['fids']
