import { useMemo } from 'react'
import { Path, useFormContext } from 'react-hook-form'

import { MultibrandAttributeType, RrnStructureFormData } from './types'

export function useAttributeValues() {
  const { watch } = useFormContext<RrnStructureFormData>()
  const attribute1Value = watch('attribute1')
  const attribute2Value = watch('attribute2')
  const attribute3Value = watch('attribute3')
  const attribute4Value = watch('attribute4')
  const attribute5Value = watch('attribute5')
  const attribute6Value = watch('attribute6')
  const attribute7Value = watch('attribute7')
  const attribute8Value = watch('attribute8')

  return useMemo(() => {
    return [
      ['attribute1', attribute1Value],
      ['attribute2', attribute2Value],
      ['attribute3', attribute3Value],
      ['attribute4', attribute4Value],
      ['attribute5', attribute5Value],
      ['attribute6', attribute6Value],
      ['attribute7', attribute7Value],
      ['attribute8', attribute8Value],
    ] as [
      Path<RrnStructureFormData>,
      MultibrandAttributeType | '' | undefined
    ][]
  }, [
    attribute1Value,
    attribute2Value,
    attribute3Value,
    attribute4Value,
    attribute5Value,
    attribute6Value,
    attribute7Value,
    attribute8Value,
  ])
}
