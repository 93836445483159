import { Navigate, Route, Routes } from 'react-router-dom'

import * as Icons from '@mui/icons-material'
import { Toolbar } from '@mui/material'

import { LoginCallback, SecurePath, SignOut } from '@lib/okta-web'
import { trpc } from '@tk/frontend/api'
import {
  AppHeader,
  RouterWhen,
  UnknownPage,
  useFeature,
  When,
} from '@tk/frontend/primitives'
import { Layout, NavButton, NavSection } from '@tk/frontend/primitives/layout'
import { telemetry } from '@tk/frontend/telemetry'

import * as admin from './ApiUserAdmin'
import { CommercialPackagesPage } from './CommercialPackages'
import * as DataExtract from './DataExtract'
import * as Entitlements from './Entitlements'
import * as fids from './Fids'
import { Home } from './Home'
import * as Attribute from './Multibrand/AttributeCrud'
import * as RrnStructure from './Multibrand/RrnStructureCrud'
import * as Stub from './Multibrand/StubCrud'
import { Playground } from './Playground'
import * as RecordDesigner from './RecordDesigner'
import * as records from './Records'
import * as roles from './Roles'
import { ExitAssumedRole } from './Roles'
import * as routes from './routes'
import * as bloomberg from './routes.bloomberg'
import * as referenceData from './routes.reference-data'

export const App = () => {
  const showUnimplemented = useFeature('unimplemented')

  trpc.healthcheck.useSuspenseQuery(undefined, { staleTime: Infinity })

  telemetry.useIdentity()

  return (
    <Routes>
      <Route path="/" element={<AuthedLayout />}>
        <Route path="/" element={<SecurePath />}>
          <Route
            index
            element={
              showUnimplemented ? (
                <Home />
              ) : (
                <Navigate
                  to={routes.app.recordManagement.defaultRecords({})}
                  replace
                />
              )
            }
          />

          <Route
            path="/"
            element={
              <RouterWhen flag="recordDesigner" can="record-projects.read" />
            }
          >
            <Route
              path={routes.app.recordDesigner.start}
              element={<RecordDesigner.CreateProjectPage />}
            />
            <Route
              path={routes.app.recordDesigner.startWithSlug}
              element={<RecordDesigner.CreateProjectViaFlowPage />}
            />
            <Route
              path={routes.app.recordDesigner.startWithSlugOld} // backward compat
              element={<RecordDesigner.CreateProjectViaFlowPage />}
            />
            <Route
              path={routes.app.recordDesigner.project}
              element={<RecordDesigner.ProjectPage />}
            />
            <Route
              path={routes.app.multibrand.stubs.table}
              element={<Stub.StubCrud />}
            />
            <Route
              path={routes.app.multibrand.stubs.createInstrument}
              element={<Stub.InstrumentCreatePage />}
            />
            <Route
              path={routes.app.multibrand.stubs.editInstrument}
              element={<Stub.InstrumentEditPage />}
            />
            <Route
              path={routes.app.multibrand.attribute}
              element={<Attribute.AttributeCrud />}
            />
            <Route
              path={routes.app.multibrand.rrnStructure.table}
              element={<RrnStructure.RrnStructureCrud />}
            />
            <Route
              path={routes.app.multibrand.rrnStructure.create}
              element={<RrnStructure.RrnStructureCreatePage />}
            />
            <Route
              path={routes.app.multibrand.rrnStructure.edit}
              element={<RrnStructure.RrnStructureEditPage />}
            />
          </Route>

          <Route
            path="/"
            element={<RouterWhen flag="dataextract" can="records.read" />}
          >
            <Route
              path={routes.app.recordManagement.records + '/*'}
              element={<records.RecordsRouter />}
            />
            <Route
              path={routes.app.recordManagement.export + '/*'}
              element={<records.ExportsRouter />}
            />
          </Route>

          <Route
            path="/"
            element={<RouterWhen flag="dataextract" can="data-extract.read" />}
          >
            <Route
              path={routes.app.extracta.reports}
              element={<DataExtract.ExtractaReportsPage />}
            />
          </Route>

          <Route
            path="/"
            element={
              <RouterWhen flag="dataextract" can="data-extract.manage" />
            }
          >
            <Route
              path={routes.app.extracta.createReport}
              element={<DataExtract.CreateReportPage />}
            />
          </Route>

          <Route path="/" element={<RouterWhen can="records.manage" />}>
            <Route
              path={routes.app.recordManagement.import + '/*'}
              element={<records.ImportsRouter />}
            />
            R
          </Route>

          <Route path="/" element={<RouterWhen can="records.manage" />}>
            <Route
              path={routes.app.recordManagement.relink + '/*'}
              element={<records.RelinkRouter />}
            />
            R
          </Route>

          <Route
            path="/"
            element={
              <RouterWhen
                can="commercial-packages.read"
                flag="commercialPackages"
              />
            }
          >
            <Route
              path={routes.app.recordManagement.commercialPackages}
              element={<CommercialPackagesPage />}
            />
          </Route>

          {referenceData.getRoutes()}
          {bloomberg.getRoutes()}

          <Route path="/" element={<RouterWhen flag="fids" />}>
            <Route
              path={routes.app.fids.groups.table}
              element={<fids.FidGroupsPage />}
            />
            <Route
              path={routes.app.fids.groups.create}
              element={<fids.FidGroupCreatePage />}
            />
            <Route
              path={routes.app.fids.groups.edit}
              element={<fids.FidGroupEditPage />}
            />
            <Route path={routes.app.fids.list} element={<fids.FidsCrud />} />
          </Route>

          <Route
            path="/"
            element={<RouterWhen flag="entitlements" can="entitlements.read" />}
          >
            <Route
              path={routes.app.entitlements.distribution}
              element={<Entitlements.DistributionCrud />}
            />

            <Route
              path={routes.app.entitlements.actor}
              element={<Entitlements.ActorCrud />}
            />

            <Route
              path={routes.app.entitlements.ruleset.table}
              element={<Entitlements.RulesetCrud />}
            />
            <Route
              path={routes.app.entitlements.ruleset.create}
              element={<Entitlements.RulesetCreatePage />}
            />
            <Route
              path={routes.app.entitlements.ruleset.edit}
              element={<Entitlements.RulesetEditPage />}
            />
          </Route>

          <Route path="/" element={<RouterWhen can="super" />}>
            <Route
              path={routes.app.admin.userRoles}
              element={<roles.UserRolesPage />}
            />
            <Route
              path={routes.app.admin.roles}
              element={<roles.RolesPage />}
            />
            <Route
              path={routes.app.admin.admin}
              element={<admin.AdminPage />}
            />
          </Route>
        </Route>
      </Route>

      <Route path="/" element={<BasicLayout />}>
        <Route path={routes.auth.SignInResponse} element={<LoginCallback />} />
        <Route
          path={routes.auth.SignOut}
          element={
            <SignOut
              onSignout={() => {
                telemetry.signout()
              }}
            />
          }
        />

        <Route path={routes.Playground} element={<Playground />} />
      </Route>

      <Route path="/" element={<AuthedLayout />}>
        <Route path="*" element={<UnknownPage />} />
      </Route>
    </Routes>
  )
}

const AuthedLayout = () => {
  return (
    <Layout
      decoration={
        <>
          <ExitAssumedRole />
        </>
      }
      sidebar={
        <>
          <Toolbar>
            <AppHeader />
          </Toolbar>

          <When flag="unimplemented">
            <NavButton label="Home" path="/" icon={<Icons.HomeRounded />} end />
          </When>

          <NavSection title="Record Management">
            <When can="records.read">
              <NavButton
                label="Records"
                path={routes.app.recordManagement.defaultRecords({})}
                icon={<Icons.TableRowsRounded />}
              />
            </When>

            <When can="commercial-packages.read" flag="commercialPackages">
              <NavButton
                label="Commercial Packages"
                path={routes.app.recordManagement.commercialPackages}
                icon={<Icons.RequestQuote />}
              />
            </When>
          </NavSection>

          <When flag="fids">
            <NavSection title="Fields">
              <NavButton
                label="Field Groups"
                path={routes.app.fids.groups.table}
                icon={<Icons.AccountTreeRounded />}
              />

              <NavButton
                label="FIDs"
                path={routes.app.fids.list}
                icon={<Icons.LabelRounded />}
              />
            </NavSection>
          </When>

          <bloomberg.Sidebar />
          <referenceData.Sidebar />

          <When flag="recordDesigner" can="record-projects.read">
            <NavSection title="Multibrand Reference Data">
              <NavButton
                label="Market Groups & Instruments"
                path={routes.app.multibrand.stubs.table}
                icon={<Icons.AccountTreeRounded />}
              />
              <NavButton
                label="Root Record Name Structures"
                path={routes.app.multibrand.rrnStructure.table}
                icon={<Icons.LabelRounded />}
              />
              <NavButton
                label="Attributes"
                path={routes.app.multibrand.attribute}
                icon={<Icons.LabelRounded />}
              />
            </NavSection>
          </When>

          <When flag="entitlements" can="entitlements.read">
            <NavSection title="Entitlements">
              <NavButton
                label="Actor"
                path={routes.app.entitlements.actor}
                icon={<Icons.LabelRounded />}
              />
              <NavButton
                label="Distribution"
                path={routes.app.entitlements.distribution}
                icon={<Icons.LabelRounded />}
              />
              <NavButton
                label="Ruleset"
                path={routes.app.entitlements.ruleset.table}
                icon={<Icons.LabelRounded />}
              />
            </NavSection>
          </When>

          <When can="super">
            <NavSection title="Admin">
              <NavButton
                label="Roles"
                path={routes.app.admin.roles}
                icon={<Icons.Shield />}
              />
              <NavButton
                label="User Roles"
                path={routes.app.admin.userRoles}
                icon={<Icons.AddModerator />}
              />
              <NavButton
                label="Api Users"
                path={routes.app.admin.admin}
                icon={<Icons.Shield />}
              />
            </NavSection>
          </When>
        </>
      }
    />
  )
}

const BasicLayout = () => {
  return <Layout />
}
