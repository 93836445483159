import { Stack } from '@mui/material'

import { getFormComponents } from '@tk/frontend/primitives'

import { RulesetCreateDto } from './types'

const Form = getFormComponents<RulesetCreateDto>()

interface Props {
  index: number
}

export function RulesetFormAccessDates({ index }: Props) {
  return (
    <>
      <Form.SectionTitle>Access Dates</Form.SectionTitle>
      <Stack direction="row">
        <Form.Field
          name={`rulesetItems.${index}.accessFrom`}
          label="From"
          input={<Form.DatePickerField />}
          info={
            <Form.FieldInfo title="The date access should start from. This is displayed and stored in the UTC timezone." />
          }
        />
        <Form.Field
          name={`rulesetItems.${index}.accessTo`}
          label="To"
          input={
            <Form.DatePickerField
              rules={{
                validate: (value, formValues) => {
                  if (
                    value &&
                    formValues.rulesetItems?.[index].accessFrom &&
                    value < formValues.rulesetItems[index].accessFrom
                  ) {
                    return 'Date must be after "from" date'
                  }

                  return true
                },
              }}
            />
          }
          info={
            <Form.FieldInfo title="The date access should end. This is displayed and stored in the UTC timezone." />
          }
        />
      </Stack>
    </>
  )
}
