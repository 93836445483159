import { generatePath } from 'react-router-dom'

import * as Icons from '@mui/icons-material'

import * as routes from '@tk/frontend/app/routes'
import { ButtonLink, When } from '@tk/frontend/primitives'

export function RrnStructureCreateButton() {
  return (
    <When can="reference-data.manage">
      <ButtonLink
        startIcon={<Icons.Create />}
        variant="contained"
        to={generatePath(routes.app.multibrand.rrnStructure.create)}
      >
        Create
      </ButtonLink>
    </When>
  )
}
