import { getFormComponents } from '@tk/frontend/primitives'

import { RulesetEditDto } from './metadata-services'
import { RulesetFormLinks } from './RulesetFormLinks'
import { RulesetItemsForm } from './RulesetItemsForm'
import { useEntitlementLists } from './useEntitlementList'

const Form = getFormComponents<RulesetEditDto>()

export function RulesetForm() {
  const { actorList, distributionList } = useEntitlementLists()

  return (
    <Form.SectionBodyGrid>
      <Form.Section title="Details">
        <Form.Field
          label="Name (Required)"
          name="name"
          input={<Form.TextField autoFocus rules={Form.rules.required} />}
        />

        <Form.Field
          label="Description (Required)"
          name="description"
          input={<Form.TextField rules={Form.rules.required} />}
        />
      </Form.Section>

      <Form.Section title="Entitlement">
        <Form.Field
          name="distribution.id"
          label="Distribution (Required)"
          input={
            <Form.SelectField
              options={distributionList}
              rules={Form.rules.required}
            />
          }
        />

        <Form.Field
          name="actor.id"
          label="Actor (Required)"
          input={
            <Form.SelectField options={actorList} rules={Form.rules.required} />
          }
        />
      </Form.Section>

      <RulesetItemsForm />

      <RulesetFormLinks />
    </Form.SectionBodyGrid>
  )
}
