import { PageContentLayout } from '@tk/frontend/primitives/layout'

import { AttributeCreateButton } from './AttributeCreateButton'
import { AttributeCrudTable } from './AttributeCrudTable'

export function AttributeCrud() {
  return (
    <PageContentLayout title="Attributes" controls={<AttributeCreateButton />}>
      <AttributeCrudTable />
    </PageContentLayout>
  )
}
