import { ChangeEvent, useCallback, useMemo, useState } from 'react'

import {
  Checkbox,
  FormControlLabel,
  InputAdornment,
  TextField,
} from '@mui/material'
import {
  DataGridPremium,
  DataGridPremiumProps,
  GridColDef,
  GridRowParams,
  useGridApiRef,
  useKeepGroupedColumnsHidden,
} from '@mui/x-data-grid-premium'

import {
  createColumns,
  setColumnDefaults,
} from '@tk/frontend/primitives/datagrid/columns'
import { useRowExpansionProps } from '@tk/frontend/primitives/datagrid/tree'

import { checkboxColumn } from './CheckBoxColumn'
import { Fid, Fids, FieldSelectionModelType } from './types'
import useFieldRowSelectionModel from './useFieldRowSelectionModel'

type FidGroupDataGridProps = {
  fids: Fids
  loading: boolean
  displaySearch?: boolean
  fieldSelectionModel: FieldSelectionModelType
  sx?: DataGridPremiumProps['sx']
}

export const FidGroupSelectionDataGrid = ({
  fids,
  loading,
  displaySearch,
  fieldSelectionModel,
  sx,
}: FidGroupDataGridProps) => {
  const apiRef = useGridApiRef()

  const [showOnlySelected, setShowOnlySelected] = useState(false)

  const onFilterChange = useCallback(
    (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      setTimeout(() => {
        apiRef.current.setQuickFilterValues(e.target.value.split(' '))
      }, 500)
    },
    [apiRef]
  )

  const filteredFids = useMemo(() => {
    return fids.filter(
      ({ rowId }) =>
        !showOnlySelected || fieldSelectionModel.model.includes(rowId)
    )
  }, [fids, fieldSelectionModel.model, showOnlySelected])

  const columns: GridColDef[] = useMemo(
    () =>
      createColumns([
        checkboxColumn(apiRef),
        {
          field: 'fidGroupName',
          headerName: 'Field Group',
          width: 300,
          type: 'string',
          groupable: true,
        },
        {
          field: 'fidName',
          headerName: 'Name/Alias',
          width: 150,
          type: 'string',
        },
        {
          field: 'acronym',
          headerName: 'Acronym',
          width: 150,
          type: 'string',
        },
        {
          field: 'externalId',
          headerName: 'Fid',
          width: 150,
          type: 'string',
        },
        {
          field: 'scope',
          headerName: 'Scope',
          width: 150,
          type: 'string',
        },
      ]).map(setColumnDefaults),
    [apiRef]
  )

  const initialState = useKeepGroupedColumnsHidden({
    apiRef,
    initialState: {
      rowGrouping: {
        model: ['fidGroupName'],
      },
    },
  })

  const rowExpansion = useRowExpansionProps(apiRef)

  const { onRowSelectionModelChange } = useFieldRowSelectionModel({
    fids,
    fieldSelectionModel,
  })

  return (
    <>
      {displaySearch && (
        <TextField
          label="Filter"
          variant="filled"
          inputProps={{
            role: 'search',
          }}
          onChange={onFilterChange}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <FormControlLabel
                  label="Show Selected Only"
                  control={
                    <Checkbox
                      checked={showOnlySelected}
                      onChange={(e) => setShowOnlySelected(e.target.checked)}
                    />
                  }
                  labelPlacement="start"
                />
              </InputAdornment>
            ),
          }}
          fullWidth
        />
      )}
      <DataGridPremium
        apiRef={apiRef}
        density="compact"
        initialState={initialState}
        disableRowSelectionOnClick
        isRowSelectable={(params: GridRowParams) =>
          (params.row as { available: boolean }).available === true
        }
        keepNonExistentRowsSelected
        isGroupExpandedByDefault={rowExpansion.isGroupExpandedByDefault}
        checkboxSelection
        getRowId={(row: Fid) => row.rowId}
        columns={columns}
        loading={loading}
        rows={filteredFids ?? []}
        rowSelectionModel={fieldSelectionModel.model}
        onRowSelectionModelChange={onRowSelectionModelChange}
        experimentalFeatures={{ lazyLoading: true }}
        sx={{
          'marginTop': '0px !important',
          '& .MuiDataGrid-columnHeader[aria-colindex="1"] .MuiCheckbox-root:has(input[type="checkbox"])':
            {
              display: 'none',
            },
          ...sx,
        }}
      />
    </>
  )
}
