import { useMemo } from 'react'
import { useNavigate } from 'react-router-dom'

import * as Icons from '@mui/icons-material'
import { Box, Button, TextField } from '@mui/material'
import { DataGridPremium, useGridApiRef } from '@mui/x-data-grid-premium'

import { When } from '@tk/frontend/primitives'
import {
  ActionButtonGroup,
  defaultProps,
  GridEnrichedColDef,
} from '@tk/frontend/primitives/datagrid'
import {
  NameDateCell,
  WrappedTextCell,
} from '@tk/frontend/primitives/datagrid/cells'
import { setColumnDefaults } from '@tk/frontend/primitives/datagrid/columns'
import {
  TreeDataGridStyles,
  useTreeGroupingProps,
} from '@tk/frontend/primitives/datagrid/tree'
import { useClientFilter } from '@tk/frontend/primitives/datagrid/useClientFilter'

import { generateRulesetEditUri } from './Ruleset.routes'
import { UnionRow } from './types'
import { useRows } from './useRows'

export function RulesetCrudTable() {
  const { rows, getTreeDataPath, getRowId } = useRows()
  const apiRef = useGridApiRef()

  const navigate = useNavigate()
  const groupingProps = useTreeGroupingProps(apiRef, {
    getTreeDataPath,
    getRowId,
  })

  const cols = useMemo<GridEnrichedColDef<UnionRow>[]>(() => {
    const columns: GridEnrichedColDef<UnionRow>[] = [
      {
        type: 'string',
        field: 'name',
        headerName: 'Name',
        flex: 1,
      },
      {
        type: 'string',
        field: 'description',
        headerName: 'Description',
        flex: 1,
      },
      {
        type: 'string',
        field: 'actor.name',
        headerName: 'Actor',
        flex: 1,
        renderCell(params) {
          if (params.row._type === 'rulesetItem') {
            return ''
          }

          const { actor } = params.row
          return <WrappedTextCell text={actor?.name} />
        },
      },
      {
        type: 'string',
        field: 'distribution.name',
        headerName: 'Distribution',
        flex: 1,
        renderCell(params) {
          if (params.row._type === 'rulesetItem') {
            return ''
          }

          const { distribution } = params.row
          return <WrappedTextCell text={distribution?.name} />
        },
      },
      {
        type: 'string',
        field: 'createdBy',
        headerName: 'Created',
        width: 150,
        editable: false,
        renderCell(params) {
          const { createdBy, createdDate } = params.row
          return <NameDateCell name={createdBy} date={createdDate} />
        },
      },
      {
        type: 'string',
        field: 'modifiedBy',
        headerName: 'Modified',
        width: 150,
        editable: false,
        renderCell(params) {
          const { modifiedBy, modifiedDate } = params.row
          return <NameDateCell name={modifiedBy} date={modifiedDate} />
        },
      },
      {
        type: 'actions',
        field: 'actions',
        align: 'right',
        width: 85,
        getActions({ row }) {
          if (row._type === 'rulesetItem') {
            return []
          }

          return [
            <When can="entitlements.manage">
              <ActionButtonGroup>
                <Button
                  color="primary"
                  startIcon={<Icons.Edit />}
                  onClick={() => navigate(generateRulesetEditUri(row.id))}
                >
                  Edit
                </Button>
              </ActionButtonGroup>
            </When>,
          ]
        },
      },
    ]
    return columns.map((col) => {
      col.filterable ??= true
      col.sortable ??= true
      setColumnDefaults(col)
      return col
    })
  }, [navigate])

  const { filteredList, filterValue, setFilterValue } = useClientFilter(rows)

  return (
    <Box display="flex" flexDirection="column" height="100%" width="100%">
      <TextField
        value={filterValue ?? ''}
        onChange={(e) => setFilterValue(e.target.value)}
        label="Filter"
        variant="filled"
        inputProps={{
          role: 'search',
        }}
        placeholder='You may use wildcards (%) to search for partial Name and Description. ie. "%ICAP%"'
        fullWidth
      />

      <TreeDataGridStyles>
        <DataGridPremium
          {...defaultProps}
          rows={filteredList}
          apiRef={apiRef}
          {...groupingProps}
          columns={cols}
          density="compact"
          initialState={{
            sorting: {
              sortModel: [{ field: 'name', sort: 'asc' }],
            },
          }}
        />
      </TreeDataGridStyles>
    </Box>
  )
}
