import { useMemo } from 'react'

import { Button, Stack } from '@mui/material'

import { Inputs, trpc, useUtils } from '@tk/frontend/api'
import {
  getFormComponents,
  usePromiseNotification,
} from '@tk/frontend/primitives'
import { MULTIBRAND_ATTRIBUTE_TYPE_OPTIONS } from '@tk/frontend/primitives/MultibrandAttributeType'

export type AttributeFormValues =
  Inputs['multibrand']['attribute']['create'] & { id: number }

const Form = getFormComponents<AttributeFormValues>()

export type AttributeFormProps = {
  onFinished?: () => void
}

export function AttributeForm({ onFinished }: AttributeFormProps) {
  const form = Form.useForm({
    defaultValues: {
      type: 'CURRENCY',
    },
  })
  const { handleSubmit: _handleSubmit } = form

  const progress = usePromiseNotification()
  const mutation = useMutation()

  const handleSubmit = useMemo(() => {
    return _handleSubmit(async (values) => {
      const promise = mutation.mutateAsync(values as any)
      await progress(promise, {
        progressMessage: 'Creating',
        successMessage: 'Created',
        failureMessage: 'Error creating',
      })

      onFinished?.()
    })
  }, [_handleSubmit, mutation, onFinished, progress])

  return (
    <Form.Provider {...form}>
      <Stack width="30rem" component="form" onSubmit={handleSubmit}>
        <Form.Field
          label="Name (Required)"
          name="name"
          input={<Form.TextField autoFocus rules={Form.rules.required} />}
        />
        <Form.Field
          label="Description (Required)"
          name="description"
          input={<Form.TextField rules={Form.rules.required} />}
        />
        <Form.Field
          label="Type (Required)"
          name="type"
          input={
            <Form.SelectField
              options={MULTIBRAND_ATTRIBUTE_TYPE_OPTIONS}
              rules={Form.rules.required}
            />
          }
        />

        <Form.Row>
          <Button type="submit">Create</Button>
        </Form.Row>
      </Stack>
    </Form.Provider>
  )
}

function useMutation() {
  const utils = useUtils()

  const { useMutation } = trpc.multibrand.attribute.create

  return useMutation({
    onSuccess() {
      utils.multibrand.attribute.invalidate()
    },
  })
}
