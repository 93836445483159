import { useMemo } from 'react'

import { trpc } from '@tk/frontend/api'
import { Option } from '@tk/frontend/primitives/forms/SelectField'

export function useStubOptions() {
  const [stubs] = trpc.multibrand.instrument.list.useSuspenseQuery()

  const stubOptions = useMemo(() => {
    return (
      stubs.map<Option>(({ id, name, multibrandMarketGroup }) => {
        const marketGroupName = multibrandMarketGroup?.name ?? ''

        return {
          value: id,
          label: marketGroupName + name,
        }
      }) ?? []
    )
  }, [stubs])

  return stubOptions
}
