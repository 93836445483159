import { Fragment, useCallback } from 'react'
import { useFieldArray, useFormContext } from 'react-hook-form'

import { Add } from '@mui/icons-material'
import { Box, Button, Divider, Stack } from '@mui/material'

import { EntitlementRulesetItemRequestDto } from '@lib/pgs'
import { getFormComponents } from '@tk/frontend/primitives'

import { getMetadataServiceByName } from './metadata-services'
import { RulesetFormAccessDates } from './RulesetFormAccessDates'
import { RulesetFormFids } from './RulesetFormFids'
import { RulesetEditDto } from './types'
import { useEntitlementLists } from './useEntitlementList'
import { useSelectedDistributionListDetails } from './useSelectedDistributionListDetails'

const Form = getFormComponents<RulesetEditDto>()

export function RulesetItemsForm() {
  const form = useFormContext<RulesetEditDto>()
  const rulesetItems = useFieldArray({
    control: form.control,
    name: 'rulesetItems',
    keyName: 'formId',
  })
  const distributionDetails = useSelectedDistributionListDetails()
  const service = getMetadataServiceByName(distributionDetails?.name)
  const entitlementData = useEntitlementLists()

  const addRulesetItem = useCallback(() => {
    const rulesetId = form.getValues('id')

    const itemToAdd: EntitlementRulesetItemRequestDto = {
      name: '',
      description: '',
      marketGroup: {
        id: -1,
      },
      fields: [],
    }

    if (typeof rulesetId === 'number' && rulesetId > 0) {
      itemToAdd.ruleset = {
        id: rulesetId,
      }
    }

    rulesetItems.append(itemToAdd)
  }, [form, rulesetItems])

  const removeRulesetItem = useCallback(
    (index: number) => {
      rulesetItems.remove(index)
    },
    [rulesetItems]
  )

  return (
    <Form.Section gridColumns={12} title="Ruleset Items">
      {rulesetItems.fields.map(({ formId, id }, index) => (
        <Fragment key={formId}>
          {id && (
            <input hidden {...form.register(`rulesetItems.${index}.id`)} />
          )}
          <Stack direction="row" data-testid={`ruleset-item-${index}-form`}>
            <Divider
              flexItem
              orientation="vertical"
              sx={{
                borderColor: (t) => t.palette.primary.light,
                borderWidth: '0.1rem',
              }}
            />
            <Stack sx={{ flex: 1 }}>
              <Stack direction="row">
                <Form.Field
                  label="Name (Required)"
                  name={`rulesetItems.${index}.name`}
                  input={<Form.TextField rules={Form.rules.required} />}
                />
                <Form.Field
                  label="Description (Required)"
                  name={`rulesetItems.${index}.description`}
                  input={<Form.TextField rules={Form.rules.required} />}
                />
              </Stack>
              <Stack direction="row">
                <Form.Field
                  label="Market Group (Required)"
                  name={`rulesetItems.${index}.marketGroup.id`}
                  input={
                    <Form.SelectField
                      options={entitlementData.marketGroupList}
                      rules={Form.rules.required}
                    />
                  }
                />
              </Stack>
              <service.MetadataForm index={index} />
              {distributionDetails?.enableAccessDates && (
                <RulesetFormAccessDates index={index} />
              )}
              {distributionDetails?.enableFields && (
                <RulesetFormFids index={index} />
              )}
            </Stack>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
              }}
            >
              <Button color="warning" onClick={() => removeRulesetItem(index)}>
                Delete
              </Button>
            </Box>
          </Stack>
          {index < rulesetItems.fields.length - 1 && <Divider />}
        </Fragment>
      ))}
      <Form.Row>
        <Button
          startIcon={<Add />}
          onClick={addRulesetItem}
          data-testid="add-ruleset-item"
        >
          Add
        </Button>
      </Form.Row>
    </Form.Section>
  )
}
