import { useCallback, useMemo } from 'react'
import { useFormContext } from 'react-hook-form'

import { EntitlementFieldRequestDto } from '@lib/pgs'
import {
  Fid,
  FieldSelectionModelType,
} from '@tk/frontend/primitives/fid-ordering'

import { RulesetCreateDto } from './metadata-services'
import { generateRowId, useFidsQuery } from './useFidsQuery'

export function useFieldSelectionModel(index: number): FieldSelectionModelType {
  const { setValue, watch } = useFormContext<RulesetCreateDto>()

  const fields = watch('rulesetItems')?.[index]?.fields

  const fidsQuery = useFidsQuery(index)

  const selectedId = useMemo(() => {
    return fields?.[0]?.fidGroupId ?? null
  }, [fields])

  const fidRowIdsAndInfo = useMemo(() => {
    return (
      fidsQuery.data?.reduce((map, fid) => {
        const { groupId, fidId } = fid
        map.set(generateRowId(groupId, fidId), fid)

        return map
      }, new Map<number, Fid>()) ?? new Map<number, Fid>()
    )
  }, [fidsQuery.data])

  const setModel = useCallback(
    (rowIds: (string | number)[]) => {
      const selectedFids = rowIds.map((rowId) =>
        fidRowIdsAndInfo.get(
          typeof rowId === 'number' ? rowId : parseInt(rowId)
        )
      )

      const transformedFids = selectedFids
        .filter(Boolean)
        .map(transformFidsQueryDataToEntitlementRulesetField)

      setValue(
        `rulesetItems.${index}.fields`,
        transformedFids as EntitlementFieldRequestDto[],
        { shouldDirty: true }
      )
    },
    [fidRowIdsAndInfo, index, setValue]
  )

  const model = useMemo(() => {
    return (
      fields?.map?.(({ fidGroupId, externalId }) =>
        generateRowId(fidGroupId, parseInt(externalId))
      ) ?? []
    )
  }, [fields])

  return {
    selectedId,
    model,
    setModel,
  }
}

function transformFidsQueryDataToEntitlementRulesetField(
  fid: Fid | undefined,
  index: number
) {
  if (!fid) {
    return
  }

  const { externalId, acronym, fidName, groupId } = fid

  return {
    externalId,
    name: acronym,
    alias: fidName,
    order: index + 1,
    fidGroupId: groupId,
  } as EntitlementFieldRequestDto
}
