import { useFormContext } from 'react-hook-form'

import { trpc } from '@tk/frontend/api'

import { RulesetCreateDto } from './metadata-services'

export function generateRowId(fidGroupId: number, fidId: number) {
  return fidGroupId * 100_000_000 + fidId
}

export function useFidsQuery(index: number) {
  const form = useFormContext<RulesetCreateDto>()
  const rulesetItems = form.watch('rulesetItems') ?? []

  const marketGroupId = rulesetItems[index]?.marketGroup?.id

  const fidsQuery = trpc.extracta.getExtractableRecordsAndFids.useQuery(
    {
      recordParams: { marketGroupId: [marketGroupId] },
      options: { excludeUnavailableFids: true },
    },
    {
      select: (data) => data.fids,
      enabled: typeof marketGroupId === 'number',
    }
  )

  return fidsQuery
}
