import { z } from 'zod'

import { EntitlementRulesetDto } from '@lib/pgs'

import { createJsonParser } from './createJsonParser'
import { DeliveryHubForm } from './DeliveryHubForm'
import {
  DeliveryHubFields,
  DistributionService,
  RulesetItemRequestDto,
  RulesetRequestDtoWithItems,
} from './types'

export type { DeliveryHubFields }

const configurationSchema = z.object({
  triggerDetails: z.object({
    time: z.array(z.string()),
    schedule: z.array(z.string()),
    timezone: z.string(),
  }),
})

type ConfigurationSchema = z.infer<typeof configurationSchema>

const parser = createJsonParser<ConfigurationSchema>(configurationSchema)

export class DeliveryHubService
  implements DistributionService<DeliveryHubFields>
{
  private readonly createMetadataString = (
    values: RulesetItemRequestDto<DeliveryHubFields>
  ) => {
    return JSON.stringify({
      triggerDetails: {
        time: values.time ?? [],
        schedule: values.schedule ?? [],
        timezone: values.timezone,
      },
    })
  }

  private readonly validateMetadata = (metadata: string) => {
    try {
      parser(metadata)
      return true
    } catch {
      console.error('Error parsing metadata')
      return false
    }
  }

  getDefaultValues = (data: EntitlementRulesetDto) => {
    const updatedData = data as Partial<
      RulesetRequestDtoWithItems<DeliveryHubFields>
    >

    if (!updatedData.rulesetItems) {
      return updatedData
    }

    updatedData.rulesetItems = updatedData.rulesetItems.map((rulesetItem) => {
      try {
        const parsed = parser(rulesetItem.metadata ?? '')

        return { ...rulesetItem, ...parsed }
      } catch {
        console.error(
          `Error parsing metadata for ruleset item "${rulesetItem.name}" to retrieve default values`
        )
        return rulesetItem
      }
    })

    return updatedData
  }

  validateRulesetItemMetadataValues = (
    values: Partial<RulesetRequestDtoWithItems<DeliveryHubFields>>
  ) => {
    const invalidIndexes: number[] = []

    if (values.rulesetItems && values.rulesetItems.length > 0) {
      values.rulesetItems.forEach((rulesetItem, index) => {
        const metadata = this.createMetadataString(rulesetItem)
        const validMetadata = this.validateMetadata(metadata ?? '')

        if (!validMetadata) {
          invalidIndexes.push(index)
          return
        }

        rulesetItem.metadata = metadata
      })
    }

    return invalidIndexes
  }

  MetadataForm = DeliveryHubForm
}

export default DeliveryHubService
