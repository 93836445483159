import { useNavigate, useParams } from 'react-router-dom'

import * as Icons from '@mui/icons-material'
import { Button } from '@mui/material'

import { useRouteEffect } from '@lib/web'
import { trpc, useUtils } from '@tk/frontend/api'
import {
  getFormComponents,
  usePromiseNotification,
} from '@tk/frontend/primitives'

import { InstrumentForm } from './InstrumentForm'
import { generateInstrumentEditUri } from './StubRouter.routes'
import { Instrument, InstrumentRequest } from './types'

const Form = getFormComponents<InstrumentRequest & { id: number }>()

export function InstrumentEditPage() {
  const { id } = useParams<{ id: string }>()
  const { initialData, instrument } = useFormInitialData(id!)

  const form = Form.useForm({
    defaultValues: initialData,
  })

  useRouteEffect(() => {
    form.reset(initialData)
  })

  const navigate = useNavigate()
  const notify = usePromiseNotification()
  const updateMutation = trpc.multibrand.instrument.update.useMutation({
    onSuccess(data) {
      navigate({
        pathname: generateInstrumentEditUri(data.id),
      })
    },
  })
  const utils = useUtils()

  const handleSubmit = form.handleSubmit(async (values) => {
    await notify(updateMutation.mutateAsync(values), {
      progressMessage: `Updating Market Instrument ${instrument.name}`,
      successMessage: `Updated Market Instrument ${values.name}`,
      failureMessage: `Failed to create Market Instrument`,
    })

    utils.multibrand.invalidate()
  })

  return (
    <Form.Provider {...form}>
      <input {...form.register('id')} hidden />
      <Form.FormStack onSubmit={handleSubmit} submitOnCtrlEnter>
        <Form.SectionTitleRow title="Edit Market Instrument">
          <Button
            startIcon={<Icons.Save />}
            type="submit"
            variant="contained"
            disabled={updateMutation.isPending || !form.formState.isDirty}
          >
            Update
          </Button>
        </Form.SectionTitleRow>

        <InstrumentForm />
      </Form.FormStack>
    </Form.Provider>
  )
}

function transformToFormData(dto: Instrument) {
  const requestDto = dto as Partial<InstrumentRequest>

  if (dto.multibrandMarketGroup) {
    requestDto.multibrandMarketGroup = {
      id: dto.multibrandMarketGroup.id,
    }
  }

  requestDto.rrnStructures = dto.rrnStructures?.map(({ id }) => ({ id })) ?? []

  return requestDto
}

function useFormInitialData(id: string) {
  const [instrument] = trpc.multibrand.instrument.get.useSuspenseQuery({
    id: parseInt(id),
  })

  return {
    initialData: transformToFormData(instrument),
    instrument: instrument,
  }
}
