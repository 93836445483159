import { useFormContext } from 'react-hook-form'

import { FormLabel, Stack } from '@mui/material'

import { getFormComponents } from '@tk/frontend/primitives'
import {
  FidGroupSelectionDataGrid,
  FidOrderingDataGrid,
} from '@tk/frontend/primitives/fid-ordering'

import { RulesetCreateDto } from './metadata-services'
import { useFidsQuery } from './useFidsQuery'
import { useFieldSelectionModel } from './useFieldSelectionModel'

const Form = getFormComponents<RulesetCreateDto>()

interface Props {
  index: number
}

export function RulesetFormFids({ index }: Readonly<Props>) {
  const { data, isPending } = useFidsQuery(index)
  const form = useFormContext<RulesetCreateDto>()
  const fieldSelectionModel = useFieldSelectionModel(index)

  const rulesetItems = form.watch('rulesetItems') ?? []
  const fields = rulesetItems[index]?.fields

  return (
    <Stack>
      <Form.SectionTitle>Field Groups Selection</Form.SectionTitle>

      {fields?.length === 0 && (
        <FormLabel error>Please Select Fields</FormLabel>
      )}

      <FidGroupSelectionDataGrid
        loading={isPending}
        fids={data ?? []}
        displaySearch
        fieldSelectionModel={fieldSelectionModel}
        sx={{ minHeight: '20rem', maxHeight: '20rem' }}
      />

      <Form.SectionTitle>Field Ordering</Form.SectionTitle>
      <FidOrderingDataGrid
        loading={isPending}
        fids={data ?? []}
        disableOverrides
        fieldSelectionModel={fieldSelectionModel}
        sx={{ minHeight: '20rem', maxHeight: '20rem' }}
      />

      <input
        hidden
        {...form.register(`rulesetItems.${index}.fields`, { required: true })}
      />
    </Stack>
  )
}
