import { useMemo } from 'react'
import { useFormContext } from 'react-hook-form'

import { RulesetCreateDto } from './metadata-services'
import { useEntitlementLists } from './useEntitlementList'

export function useSelectedDistributionListDetails() {
  const form = useFormContext<RulesetCreateDto>()
  const distributionId = form.watch('distribution.id')
  const { distributions } = useEntitlementLists()

  const selectedDistributionListDetails = useMemo(() => {
    return distributions.find(({ id }) => id === distributionId)
  }, [distributionId, distributions])

  return selectedDistributionListDetails
}
